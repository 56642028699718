import "../../styles/snake.css"

const Snake = () => {
    return (
        <>
            <div className="splashcontainer">
                {/* <div className="snake">
                    <div className="dot dot-1"></div>
                    <div className="dot dot-2"></div>
                    <div className="dot dot-3"></div>
                    <div className="dot dot-4"></div>
                    <div className="dot dot-5"></div>
                    <div className="dot dot-6"></div>
                    <div className="dot dot-7"></div>
                </div> */}
                <div className="lds-dual-ring"></div>
            </div>
        </>
    )
}

export default Snake