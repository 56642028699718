import { mixed, object, string } from "yup"
const validationSchema = object().shape({
    photo: mixed().required("Please upload a photo"),
    addressProof: mixed().required("Please upload an address proof"),
    idProofType: string().required("Please select an id proof type"),
    idProof: mixed().required("Please upload an id proof"),
    CovidVaccinationStatus: string().required("Please select vaccination status"),
    passportFile: mixed(),
    visaFile: mixed(),
})

export default validationSchema