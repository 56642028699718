interface dropdownProps {
  label: string;
  options: Array<{ label: string; value: string }>;
  ariaFor: string;
  placeholderOption: string;
  name: string;
  value?: string ;
  id: string;
  errorMessage?: any;
  hookFormRegister?: object;
  disabled?: boolean;
}

const Dropdown = (props: dropdownProps) => {
  const {
    label,
    value,
    options,
    ariaFor,
    placeholderOption,
    name,
    id,
    errorMessage = null,
    hookFormRegister = {},
    disabled = false,
  } = props;
  return (
    <>
      <label
        className="text-gray-700 flex flex-col relative mt-5"
        htmlFor={ariaFor}
      >
        <div className={'text-444Black sans-normal text-sm'}>{label}</div>
        <select
          id={id}
          className="scroll-m-80 mt-1  block w-80 h-12 py-2 px-4 border border-slate-300 bg-white rounded-sm shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
          name={ariaFor}
          {...hookFormRegister}
          disabled={disabled}
          value={value}
        >
          {/* TODO Update the keys */}
          <option disabled hidden value="">
            {placeholderOption}
          </option>
          {options.map((a, index) => (
            <option value={a.value} key={index + '' + { ariaFor }}>
              {a.label}
            </option>
          ))}
        </select>

        {errorMessage && (
          <>
            <p className="text-base font-semibold text-red-500">{errorMessage}</p>
          </>
        )}
      </label>
    </>
  );
};

export default Dropdown;
