import { yupResolver } from "@hookform/resolvers/yup";
import { getGlobalState, useGlobalState } from "store";
import PrimaryButton from "components/PrimaryButton";
import StepCardHolder from "components/StepCardHolder";
import TextField from "components/TextField";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form";
import { hasSSOProfile, hideLoadingOverlay, scrollToNodeWithId, showLoadingOverlay } from "utils";
import validationSchema from "./validations";

const CoParticipants = (props: any, ref: any) => {
    const { coParticipants } = useMemo(() => ({ ...getGlobalState() }), []);
    const { userEmail } = useGlobalState();
    const [numberOfParticipants, setNumberOfParticipants] = useState<number>(0)
    const [accountFound, setAccountFound] = useState<boolean | null>(null)
    const [isPrimaryEmail, setIsPrimaryEmail] = useState<boolean | null>(null)
    const {
        register,
        control,
        handleSubmit,
        reset,
        trigger,
        setError,
        getValues,
        formState: { errors }
    } = useForm<{
        coParticipants: {
            firstName: string,
            lastName: string,
            email: string,
        }[]
    }>({
        resolver: yupResolver(validationSchema),
        mode: 'all',
        defaultValues: {
            coParticipants: coParticipants ? coParticipants?.map(x => ({ firstName: x?.data?.firstName as string || "", lastName: x?.data?.lastName as string || "", email: x?.data?.email as string || "" })) : []
        }
    });
    const { fields, append } = useFieldArray({
        control,
        name: "coParticipants",
    });

    useImperativeHandle(ref, () => ({
        getData: async () => {
            try {
                const cers = await trigger();
                if (Object.keys(errors).length > 0 || cers === false) {
                    scrollToNodeWithId('em-card');
                    throw new Error('Fill mandatory fields in co participant details')
                }
                const targetData = { ...getValues() };
                showLoadingOverlay()
                for (let i = 0; i < targetData.coParticipants.length; i++) {
                    const { email, firstName, lastName } = targetData.coParticipants[i];
                    const resp = await hasSSOProfile(email)
                    const { profileId } = resp
                    if (userEmail?.trim().toLowerCase() === email?.trim().toLowerCase()) {
                        setIsPrimaryEmail(true);
                        scrollToNodeWithId('cop-card');
                        throw new Error('Co Participant email cannot be same as primary email.')
                    }
                    if (!profileId) {
                        setAccountFound(false)
                        scrollToNodeWithId('cop-card');
                        throw new Error("co participant doesn't have sso profile ");
                    }
                }
                setIsPrimaryEmail(false);
                setAccountFound(true)
                hideLoadingOverlay()
                return targetData;
            } catch (error) {
                throw error;
            }
        },
        setNumberOfParticipants: (val: number) => {
            console.log('setting number of participants', val)
            setNumberOfParticipants(val)
            reset({
                coParticipants: []
            })
            for (let i = 0; i < val; i++) {
                append({
                    firstName: "",
                    lastName: "",
                    email: ""
                }, { shouldFocus: false })

            }
        }
    }));

    const onSubmit = async (data: any) => {
        try {
            console.log(':SROLLING TO');
            scrollToNodeWithId('d-card');
        } catch (error) {
            console.log('error in submit', error);
        }
    };

    if (numberOfParticipants <= 0) {
        return null
    }


    return (
        <>
            <StepCardHolder
                heading={'Co Participants Details'}
                subHeadingHtml={`<div>
                 Note: Co Participant should have an isha account.
                <p>You can share this link <strong style="color:rgb(153 27 27);"><a href='https://isha.co/account' target="_blank" rel="noopener">https://isha.co/account</a></strong> with them to create an isha account and then try adding them as your co participant</p>
                </div>`
                }
                id={'cop-card'}

            >
                {fields.map((item, index) => (

                    <div className="responsive-wrapper" key={index}>
                        <TextField
                            hookFormRegister={register(`coParticipants.${index}.firstName`)}
                            id={'firstName'}
                            label={'First Name*'}
                            name={'`coParticipants.${number}.firstName`'}
                            placeholderText={'First Name'}
                            errorMessage={errors.coParticipants?.[index]?.["firstName"]?.message}
                        />
                        <TextField
                            hookFormRegister={register(`coParticipants.${index}.lastName`)}
                            id={'lastName'}
                            label={'Last Name*'}
                            name={'`coParticipants.${index}.lastName`'}
                            placeholderText={'Last Name'}
                            errorMessage={errors.coParticipants?.[index]?.["lastName"]?.message}
                        />
                        <TextField
                            hookFormRegister={register(`coParticipants.${index}.email`)}
                            id={'`coParticipants.${index}.email`'}
                            label={'Email *'}
                            name={'`coParticipants.${index}.email`'}
                            placeholderText={'Email'}
                            errorMessage={errors.coParticipants?.[index]?.["email"]?.message}
                        />

                    </div>
                ))}
                {
                    accountFound === false && <div className="mt-4 text-base font-semibold text-red-500">
                        * Co Participant email does not have an isha account.<br />
                        You can share this link <a className="underline cursor-pointer text-blue-900" href={"https://isha.co/account"} target={"_blank"}>https://isha.co/account</a> with them to create an isha account and then try adding them as your co participant.
                    </div>
                }{
                    isPrimaryEmail === true && <div className="mt-4 text-base font-semibold text-red-500">
                        * Co Participant email cannot be same as primary email.<br />
                    </div>
                }
                <PrimaryButton
                    text={'NEXT'}
                    onClick={handleSubmit(onSubmit)}
                    id={`pp-card-submit`}
                />
            </StepCardHolder>

        </>
    )
}

export default forwardRef(CoParticipants)
