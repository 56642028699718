interface IProps {
  text: string;
  targetUrl: string;
}

const DownloadButton = (props: IProps) => {
  const { text, targetUrl } = props;
  const onClick = () => {
    window.open(targetUrl);
  };
  return (
    <div className={'flex flex-col mt-6'}>
      <div className="text-444Black sans-normal text-sm mb-1 w-80">{text}</div>
      <div
        onClick={onClick}
        className="sans-normal flex flex-col relative items-center justify-center text-base text-237Blue w-80 h-12  self-center border-237Blue border-2  focus:ring-indigo-500 focus:ring-offset-indigo-200  transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
      >
        Download
      </div>
    </div>
  );
};

export default DownloadButton;
