import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/react';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Dropdown from '../../../../components/DropDown';
import HookFormDevTool from '../../../../components/HookFormDevTool';
import PrimaryButton from '../../../../components/PrimaryButton';
import StepCardHolder from '../../../../components/StepCardHolder';
import UploadButton from '../../../../components/UploadButton';
import {
    COVID_VACCINATION_TYPES,
    ID_PROOF_TYPES
} from '../../../../constants';
import { getGlobalState, useGlobalState } from '../../../../store';
import { GTMPush, scrollToNodeWithId, uploadFile } from '../../../../utils';
import validationSchema from './validations';
type IFormFields = {
    photo: string | File | null;
    addressProof: string | File | null;
    idProofType: string | null;
    idProof: string | File | null;
    passportFile?: string | File | null;
    visaFile?: string | File | null;
    CovidVaccinationStatus: string | null;
    passport?: string | null;
    visa?: string | null;
}
const DocumentUpload = (_props: any, ref: any) => {
    const { photo: photoUrl, addressProof: addressProofUrl, idProof: idProofUrl, idProofType, passport: passportUrl, visa: visaUrl, CovidVaccinationStatus } = useMemo(() => ({ ...getGlobalState() }), [])
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        control,
        trigger,
        formState: { errors },
    } = useForm<IFormFields>({
        resolver: yupResolver(validationSchema),
        defaultValues: { photo: photoUrl || undefined, addressProof: addressProofUrl || undefined, idProof: idProofUrl || undefined, idProofType: idProofType || undefined, passportFile: passportUrl || undefined, visaFile: visaUrl || undefined, CovidVaccinationStatus: CovidVaccinationStatus || undefined },
        mode: 'onBlur',
    });
    const { userId } = useGlobalState() as { userId: string, existingSubmission: ISubmission | null };
    const [isIndian, setIsIndian] = useState<boolean | null>(null)
    const [nextFormId, setNextFormId] = useState<string>('d-card');
    useImperativeHandle(ref, () => ({
        getData: async () => {
            try {
                const cers = await trigger(undefined, {
                    shouldFocus: true
                });
                if (Object.keys(errors).length > 0 || cers === false) {
                    scrollToNodeWithId('d-card');
                    throw new Error('Select all required documents');
                }
                const targetData = { ...getValues() };
                try {
                    if (typeof targetData.photo !== 'string')
                        targetData.photo = await uploadFile(targetData.photo! as File, `${userId}-photo`, {
                            userId, documentName: "user photo"
                        });
                    if (typeof targetData.addressProof !== 'string')
                        targetData.addressProof = await uploadFile(targetData.addressProof! as File, `${userId}-addressProof`, {
                            userId, documentName: "address proof"
                        });
                    if (typeof targetData.idProof !== 'string')
                        targetData.idProof = await uploadFile(targetData.idProof! as File, `${userId}-idProof`, {
                            userId, documentName: "id proof"
                        });
                    if (!isIndian) {
                        if (typeof targetData.passportFile !== 'string')
                            targetData.passport = await uploadFile(targetData.passportFile! as File, `${userId}-passportFile`, {
                                userId, documentName: "passport"
                            });
                        else
                            targetData.passport = targetData.passportFile
                        delete targetData.passportFile;
                        if (targetData.visaFile) {
                            if (typeof targetData.visaFile !== 'string')
                                targetData.visa = await uploadFile(targetData.visaFile! as File, `${userId}-visaFile`, {
                                    userId, documentName: "visa"
                                });
                            else targetData.visa = targetData.visaFile;
                            delete targetData.visaFile;
                        }
                        else {
                            delete targetData.visa;
                            delete targetData.visaFile;
                        }
                    } else {
                        delete targetData.passportFile;
                        delete targetData.visaFile;
                        delete targetData.passport;
                        delete targetData.visa;
                    }
                    return targetData;
                } catch (error: any) {
                    throw error
                }
            }
            catch (error) {
                throw error
            }
        },
        setIndian: (cor: string, nat: string) => {
            if (cor && nat) {
                setIsIndian(cor === "IN" && nat === "IN")
            }
        },
        setNextFormId: (formId: string) => {
            setNextFormId(formId)
        }
    }));
    const onSubmit = async (data: any) => {
        try {
            console.log('data', data)
            scrollToNodeWithId(nextFormId);
            GTMPush(userId, {
                event: 'nagablr_vp_pushed',
                virtual_path: '/nagablr_vp_document_added'
            });
        } catch (error) {
            console.log('error in submit', error);
        }
    };
    return (
        <>
            <StepCardHolder
                heading={'Document Upload'}
                subHeading={'Please provide the following documents'}
                id={'doc-card'}
            >
                <div className="responsive-wrapper">
                    <UploadButton
                        text={'Photo* (Max 2MB)'}
                        name={'photo'}
                        label={'photo'}
                        control={control}
                        errorMessage={errors?.photo?.message}
                        defaultUrl={photoUrl || undefined}
                    />
                    <UploadButton
                        text={'Address Proof* (Max 2MB)'}
                        name={'addressProof'}
                        label={'address proof'}
                        control={control}
                        errorMessage={errors?.addressProof?.message}
                        defaultUrl={addressProofUrl || undefined}
                    />
                </div>
                <div className="responsive-wrapper">
                    <Dropdown
                        hookFormRegister={register('idProofType')}
                        ariaFor={'idProofType'}
                        id={'idProofType'}
                        label={'ID Proof Type*'}
                        name={'idProofType'}
                        options={ID_PROOF_TYPES}
                        placeholderOption={'Select ID Proof Type'}
                        errorMessage={errors?.idProofType?.message}
                    />

                    <UploadButton
                        text={'ID Proof* (Max 2MB)'}
                        name={'idProof'}
                        label={'id proof'}
                        control={control}
                        errorMessage={errors?.idProof?.message}
                        defaultUrl={idProofUrl || undefined}
                    />

                </div>
                {isIndian !== null && !isIndian &&
                    <div className="responsive-wrapper">
                        <UploadButton
                            text={'passport File* (Max 2MB)'}
                            name={'passportFile'}
                            label={'passport'}
                            control={control}
                            errorMessage={errors?.passportFile?.message}
                            defaultUrl={passportUrl || undefined}
                        />
                        <UploadButton
                            text={'Visa/ OCI / PIO (Max 2MB)'}
                            name={'visaFile'}
                            label={'visa'}
                            control={control}
                            errorMessage={errors?.visaFile?.message}
                            defaultUrl={visaUrl || undefined}
                        />
                    </div>}
                <div className="flex flex-row items-center gap-10">
                    <Dropdown
                        hookFormRegister={register('CovidVaccinationStatus')}
                        ariaFor={'CovidVaccinationStatus'}
                        id={'CovidVaccinationStatus'}
                        label={'Covid Vaccination Status*'}
                        name={'CovidVaccinationStatus'}
                        options={COVID_VACCINATION_TYPES}
                        placeholderOption={'Select Covid Vaccination Status'}
                        errorMessage={errors?.CovidVaccinationStatus?.message}
                    />
                </div>

                <PrimaryButton
                    text={'NEXT'}
                    onClick={handleSubmit(onSubmit)}
                    id={`pp-card-submit`}
                />
            </StepCardHolder>
            {/* <HookFormDevTool control={control} /> */}
        </>
    );
};

export default forwardRef(DocumentUpload);
