import '../../styles/loader.css';
const FullScreenLoading = () => {
  return (
    <div
      id={'full-screen-loader'}
      className={
        'w-screen h-screen fixed bg-black bg-opacity-40 flex flex-col items-center justify-center hidden z-50'
      }
    >
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default FullScreenLoading;
