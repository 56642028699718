import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from 'components/Checkbox';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getGlobalState, IGlobalStateProps, useGlobalState } from 'store';
import Dropdown from '../../../../components/DropDown';
import HookFormDevTool from '../../../../components/HookFormDevTool';
import PrimaryButton from '../../../../components/PrimaryButton';
import StepCardHolder from '../../../../components/StepCardHolder';
import TextField from '../../../../components/TextField';
import { getDetailsOfPinCode, GTMPush, scrollToNodeWithId } from '../../../../utils';
import { COUNTRY_DATA } from '../../../../utils/countryData';
import validationSchema from './validations';

type IFormFields = Pick<IGlobalStateProps, 'line1' | 'city' | 'state' | 'pinCode' | 'countryOfResidence' | 'nationality'> & {
  shippingLine1: string | null;
  shippingCity: string | null;
  shippingState: string | null;
  shippingPinCode: string | null;
  shippingCountry: string | null;
  sameAsBilling: "0" | "1"
};

const AddressDetails = (_: any, ref: any) => {
  const { state, city, pinCode, line1, shipping, dependentOf } = useMemo(() => ({ ...getGlobalState() }), []);
  const { countryOfResidence, nationality, userId } = useGlobalState();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm<IFormFields>({
    defaultValues: {
      countryOfResidence: countryOfResidence || undefined,
      nationality: nationality || undefined,
      city: city || undefined, state: state || undefined, pinCode: pinCode || undefined, line1: line1 || undefined,
      shippingLine1: shipping?.line1 || undefined,
      shippingCity: shipping?.city || undefined,
      shippingState: shipping?.state || undefined,
      shippingPinCode: shipping?.pinCode || undefined,
      shippingCountry: shipping?.country || undefined,
      sameAsBilling: "1"
    },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });
  const [showShippingModal, setShippingModal] = useState(false);
  useImperativeHandle(ref, () => ({
    getData: async () => {
      const cers = await trigger();
      if (Object.keys(errors).length > 0 || cers === false) {
        scrollToNodeWithId('ad-card');
        throw new Error('Fill mandatory fields in address details')
      }
      return { ...getValues() };
    }
  }));
  const currentLine1 = watch('line1')
  const currentPinCode = watch('pinCode');
  const currentCity = watch('city')
  const currentState = watch('state');
  const currentCountryOfResidence = watch('countryOfResidence');
  const countryOptions = useRef(
    COUNTRY_DATA.map((a) => ({
      label: a.countryName,
      value: a.countryCode,
    }))
  );
  const shippingCountryOptions = useRef(
    COUNTRY_DATA.filter(x => x.countryCode === 'IN').map((a) => ({
      label: a.countryName,
      value: a.countryCode,
    }))
  );
  const isShippingRequired = countryOfResidence === 'IN' && nationality === 'IN' && !dependentOf;
  const sameAsBilling = watch('sameAsBilling');
  useEffect(() => {
    setValue('countryOfResidence', countryOfResidence);
  }, [countryOfResidence])
  useEffect(() => {
    setValue('nationality', nationality);
  }, [nationality])
  useEffect(() => {
    const getPinCodeDetails = async () => {
      try {
        const resp = await getDetailsOfPinCode(
          countryOfResidence,
          currentPinCode
        );
        if (Object.keys(resp).length <= 0)
          return;
        const { defaultcity, state } = resp;
        setValue('city', defaultcity);
        setValue('state', state);
        await trigger();
      } catch (error) {
        console.log('error in getPinCodeDetails-->', error);
        throw error;
      }
    };
    if (countryOfResidence !== undefined && currentPinCode) {
      if (countryOfResidence === 'IN' && currentPinCode.length === 6)
        getPinCodeDetails();
      else if (countryOfResidence !== 'IN' && currentPinCode.length >= 5)
        getPinCodeDetails();
    }
  }, [currentPinCode, countryOfResidence]);
  useEffect(() => {
    (async () => {
      if (isShippingRequired) {
        if (sameAsBilling === '1') {
          setValue('shippingLine1', currentLine1);
          setValue('shippingCity', currentCity)
          setValue('shippingState', currentState)
          setValue('shippingPinCode', currentPinCode)
          setValue('shippingCountry', currentCountryOfResidence)
          await trigger();
        }
        else {
          setValue('shippingLine1', '');
          setValue('shippingCity', '')
          setValue('shippingState', '')
          setValue('shippingPinCode', '')
          setValue('shippingCountry', '')
          await trigger();
        }
      }
    })()
  }, [currentLine1, currentPinCode, currentCity, currentState, currentCountryOfResidence, isShippingRequired, sameAsBilling])
  const onSubmit = async (data: any) => {
    try {
      if (!isShippingRequired) {
        scrollToNodeWithId('em-card');
        GTMPush(userId!, {
          event: 'nagablr_vp_pushed',
          virtual_path: '/nagablr_vp_address_details_added'
        });
      }
      else {
        setShippingModal(true);
      }
    } catch (error) {
      console.log('error in submit', error);
    }
  };
  return (
    <>
      <StepCardHolder
        heading={'Address Details'}
        subHeading={'Please provide your address details'}
        id={'ad-card'}
      >
        <div className="responsive-wrapper">
          <Dropdown
            label="Nationality"
            hookFormRegister={register('nationality')}
            ariaFor={'nationality'}
            id={'nationality'}
            name={'nationality'}
            options={countryOptions.current}
            placeholderOption={'Nationality '}
            errorMessage={errors?.nationality?.message}
            disabled={true}
          />
          <Dropdown
            label="Country of Residence*"
            hookFormRegister={register('countryOfResidence')}
            ariaFor={'countryOfResidence'}
            id={'countryOfResidence'}
            name={'countryOfResidence'}
            options={countryOptions.current}
            placeholderOption={'Country of Residence '}
            errorMessage={errors?.countryOfResidence?.message}
            disabled={true}
          />
          <TextField
            hookFormRegister={register('pinCode')}
            id={'pinCode'}
            label={'Pincode *'}
            name={'pinCode'}
            placeholderText={'Pincode/Zipcode'}
            errorMessage={errors?.pinCode?.message}
          // dataType={'number'}
          />
          <TextField
            hookFormRegister={register('line1')}
            id={'line1'}
            label={'Address *'}
            name={'line1'}
            placeholderText={'Address'}
            errorMessage={errors?.line1?.message}
          />

          <TextField
            hookFormRegister={register('city')}
            id={'City'}
            label={'City*'}
            name={'city'}
            placeholderText={'City/ Town/ District'}
            errorMessage={errors?.city?.message}
          />
          <TextField
            hookFormRegister={register('state')}
            id={'state'}
            label={'State*'}
            name={'state'}
            placeholderText={'State/Province'}
            errorMessage={errors?.state?.message}
          />
        </div>
        {
          isShippingRequired &&
          <>
            <div className='serif-medium text-base md:text-xl py-8 pb-2'>
              Shipping Address
            </div>
            <div>
              <Checkbox
                id={'same-as-billing-address'}
                mode={'RADIO'}
                label={
                  <>
                    Do you want to ship Naga Pratishta wristband to above address?
                  </>
                }
                hookFormRegister={register('sameAsBilling')}
                name={'sameAsBilling'}
              />
              <div className="responsive-wrapper">
                <Dropdown
                  label="Country*"
                  hookFormRegister={register('shippingCountry')}
                  ariaFor={'shippingCountry'}
                  id={'shippingCountry'}
                  name={'shippingCountry'}
                  options={shippingCountryOptions.current}
                  placeholderOption={'Shipping Country '}
                  errorMessage={errors?.shippingCountry?.message}
                />
                <TextField
                  hookFormRegister={register('shippingPinCode')}
                  id={'shippingPinCode'}
                  label={'Shipping PinCode *'}
                  name={'shippingPinCode'}
                  placeholderText={'Pincode/Zipcode'}
                  errorMessage={errors?.shippingPinCode?.message}
                // dataType={'number'}
                />
                <TextField
                  hookFormRegister={register('shippingLine1')}
                  id={'shippingLine1'}
                  label={'Address *'}
                  name={'shippingLine1'}
                  placeholderText={'Address'}
                  errorMessage={errors?.shippingLine1?.message}
                />

                <TextField
                  hookFormRegister={register('shippingCity')}
                  id={'shippingCity'}
                  label={'City*'}
                  name={'shippingCity'}
                  placeholderText={'City/ Town/ District'}
                  errorMessage={errors?.shippingCity?.message}
                />
                <TextField
                  hookFormRegister={register('shippingState')}
                  id={'shippingState'}
                  label={'State*'}
                  name={'shippingState'}
                  placeholderText={'State/Province'}
                  errorMessage={errors?.shippingState?.message}
                />
              </div>
            </div>
          </>
        }
        <PrimaryButton
          text={'NEXT'}
          onClick={handleSubmit(onSubmit)}
          id={`pp-card-submit`}
        />
        <HookFormDevTool control={control} />
      </StepCardHolder>
      {
        showShippingModal &&
        <div className='fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-50 sans-normal-16 z-50'>
          <div className='min-w-lg max-w-xl w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-4 md:px-12 py-8 rounded'>
            <div className='serif-medium text-base md:text-xl mx-auto'>
              Shipping Address Confirmation
            </div>
            <div className='pt-4 sans-normal-16'>
              Do you confirm to ship your naga pratishta wrist band to the below address ?
            </div>
            <div className='pt-4 sans-normal-16 space-y-1'>
              <p>{getValues('shippingLine1')},</p>
              <p>{getValues('shippingCity')},</p>
              <p>{getValues('shippingState')},</p>
              <p>{countryOptions.current.find(x => x.value === getValues('shippingCountry'))?.label} - {getValues('shippingPinCode')},</p>
            </div>
            <div className='flex flex-row space-x-4 sans-normal-16 pt-4'>
              <div
                className='bg-237Blue px-12 py-2 text-white h-min rounded hover:shadow-lg cursor-pointer'
                onClick={() => {
                  setShippingModal(false);
                  scrollToNodeWithId('em-card');
                  GTMPush(userId!, {
                    event: 'nagablr_vp_pushed',
                    virtual_path: '/nagablr_vp_address_details_added'
                  });
                }}
              >
                Yes
              </div>
              <div
                className='bg-237Blue px-12 py-2 text-white h-min rounded hover:shadow-lg cursor-pointer'
                onClick={() => {
                  setShippingModal(false);
                }}
              >
                No
              </div>

            </div>
          </div>
        </div>
      }
    </>
  );
};

export default forwardRef(AddressDetails);
