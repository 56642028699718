import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { setGlobalState } from "store";

interface IProp {
    formFieldName: string;
    stateFieldName: string;
    control: any
}
function useWatchUpdateGlobalState({ formFieldName, stateFieldName, control }: IProp) {
    const value = useWatch({ control, name: formFieldName });
    useEffect(() => {
        setGlobalState({ [stateFieldName]: value });
    }, [value])
    return null;
}

export default useWatchUpdateGlobalState;