import { useState } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import toast from 'react-hot-toast';
interface IProps {
  text: string;
  name: string;
  errorMessage?: any;
  control: any | undefined;
  label: string;
  defaultUrl?: string
  mimeTypes?: string;
}

const UploadButton = (props: IProps) => {
  const {
    text,
    defaultUrl,
    name,
    errorMessage,
    control,
    label,
    mimeTypes = 'image/png, image/jpeg',
  } = props;
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const { field } = useController({ control, name });

  const onSelectFile = async (ev: any) => {
    try {
      const targetFile = ev.target.files[0];

      const allowedFileEndings = mimeTypes.split(',').map((x) => x.trim());
      if (!allowedFileEndings.includes(targetFile.type)) {
        toast.error(`File must one of ${mimeTypes}`);
        ev.target.value = null;
        setCurrentFile(null);
        return;
      }

      if (targetFile?.size > 2097152) {
        toast.error('Max file size is 2MB');
        ev.target.value = null;
        setCurrentFile(null);
        return;
      }

      setCurrentFile(ev.target.files?.[0]);
      // const fileUrl = URL.createObjectURL(ev.target.files?.[0]);
      // console.log(name, fileUrl);
      field.onChange(ev.target.files?.[0]);
    } catch (error) {
      console.log('error in onSelectFile-->', error);
      throw error;
    }
  };

  return (
    <div className={'flex flex-col mt-6'}>
      <div className="text-444Black sans-normal text-sm mb-1 w-80">{text}</div>
      <div className="sans-normal flex flex-col relative items-center justify-center text-base text-237Blue w-80 h-12  self-center border-237Blue border-2  focus:ring-indigo-500 focus:ring-offset-indigo-200  transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
        {!defaultUrl && !currentFile ? 'Upload (2MB Max)' : 'Re Upload (2MB Max)'}
        <input
          onChange={onSelectFile}
          type="file"
          accept={mimeTypes}
          // ref={hookFormRegister}
          name={name}
          className="cursor-pointer absolute block py-2 px-4 w-full opacity-0 pin-r pin-t w-80 h-12"
        />
      </div>
      <div
        className="text-blue-700 underline cursor-pointer"
        onClick={() => {
          if (currentFile) {
            currentFile && window.open(URL.createObjectURL(currentFile));
            return;
          }
          if (defaultUrl) {
            window.open(defaultUrl, '_blank', 'noopener');
          }
        }}
      >
        {(currentFile && `${currentFile.name} (preview)`) || (defaultUrl && `Uploaded ${label} File`)}
      </div>
      {errorMessage && (
        <>
          <p className="text-base font-semibold text-red-500">{errorMessage}</p>
        </>
      )}
    </div>
  );
};

export default UploadButton;
