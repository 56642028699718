import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from 'components/Checkbox';
import DateOnly from 'components/DateOnly';
import DownloadButton from 'components/DownloadButton';
import Dropdown from 'components/DropDown';
import HookFormDevTool from 'components/HookFormDevTool';
import Phone from 'components/Phone';
import PrimaryButton from 'components/PrimaryButton';
import StepCardHolder from 'components/StepCardHolder';
import TextField from 'components/TextField';
import UploadButton from 'components/UploadButton';
import { ENVIRONMENT_TYPE } from 'config';
import { GENDERS_OPTIONS, ID_PROOF_TYPES } from '../../../../constants';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { getGlobalState, IGlobalStateProps, setGlobalState, useGlobalState } from 'store';
import { GTMPush, scrollToNodeWithId, uploadFile } from 'utils';
import validationSchema from './validations';
import { differenceInYears } from 'date-fns';
import WatchFormAndUpdateGlobalState from '../WatchFormAndUpdateGlobalState';

type IFormFields = Pick<IGlobalStateProps, 'firstName' | 'lastName' | 'age' | 'gender' | 'sameAsPhone' | 'guardianIdProofType'> & {
    email: string | null;
    dob: Date | null;
    phone: string | null;
    phoneCode: string | null;
    wPhone: string | null;
    wPhoneCode: string | null;
    signedConsentForm?: string | File | null;
    guardianAddressProof?: string | File | null;
    guardianIdProofFile?: string | File | null;
    guardianPhoto?: string | File | null;
}
const MINIMUM_AGE = 12;
const PersonalDetails = (_props: any, ref: any) => {
    const { firstName, lastName, userEmail, userId, gender, dob, age,
        sameAsPhone, userCurrentPhoneCode, userCurrentPhone, userWPhoneCode, userWPhone,
        signedConsentForm: signedConsentFormUrl, guardianPhoto: guardianPhotoUrl, guardianAddressProof: guardianAddressProofUrl, guardianIdProofType, guardianIdProofFile: guardianIdProofFileUrl
    } = useMemo(() => ({ ...getGlobalState() }), []);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        getValues,
        control,
        trigger,
        formState: { errors },
    } = useForm<IFormFields>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: firstName || undefined, lastName: lastName || undefined, email: userEmail || undefined, age: age || undefined, gender: gender || undefined, dob: ((dob && new Date(dob)) || undefined) as Date | undefined,
            phone: userCurrentPhone || undefined, phoneCode: userCurrentPhoneCode || undefined,
            wPhone: userWPhone || undefined, wPhoneCode: userWPhoneCode || undefined, sameAsPhone: sameAsPhone || undefined,
            signedConsentForm: signedConsentFormUrl || undefined, guardianPhoto: guardianPhotoUrl || undefined, guardianAddressProof: guardianPhotoUrl || undefined, guardianIdProofType: guardianIdProofType || undefined, guardianIdProofFile: guardianIdProofFileUrl || undefined
        },
        mode: 'onBlur',
    });
    const currentAge = watch("age");
    const currentDateOfBirth: Date | null = watch("dob");
    const isMinor = +(currentAge as number) < 18 && +(currentAge as number) !== 0;
    const currentSameAsPhone = watch("sameAsPhone");
    useImperativeHandle(ref, () => ({
        getData: async () => {
            try {
                const cers = await trigger();
                if (Object.keys(errors).length > 0 || cers === false) {
                    scrollToNodeWithId('pd-card');
                    throw new Error('Fill mandatory fields in personal details');
                }
                const targetData = { ...getValues() };
                if (isMinor) {
                    if (typeof targetData.signedConsentForm !== 'string')
                        targetData.signedConsentForm = await uploadFile(targetData.signedConsentForm! as File, `${userId}-consentForm`, {
                            userId: userId as string, documentName: 'guardian consent form'
                        });
                    if (typeof targetData.guardianPhoto !== 'string')
                        targetData.guardianPhoto = await uploadFile(targetData.guardianPhoto! as File, `${userId}-guardianPhoto`, {
                            userId: userId as string, documentName: 'guardian photo'
                        });
                    if (typeof targetData.guardianIdProofFile !== 'string')
                        targetData.guardianIdProofFile = await uploadFile(targetData.guardianIdProofFile! as File, `${userId}-guardianIdProofFile`, {
                            userId: userId as string, documentName: 'guardian id proof'
                        });
                    if (typeof targetData.guardianAddressProof !== 'string')
                        targetData.guardianAddressProof = await uploadFile(targetData.guardianAddressProof! as File, `${userId}-guardianAddressProof`, {
                            userId: userId as string, documentName: 'guardian address proof'
                        });
                }
                else {
                    delete targetData.signedConsentForm;
                    delete targetData.guardianPhoto;
                    delete targetData.guardianIdProofFile;
                    delete targetData.guardianAddressProof;
                }
                return targetData;
            } catch (error) {
                throw error;
            }
        },
    }));
    useEffect(() => {
        if (currentSameAsPhone) {
            setValue("wPhone", getValues('phone'));
            setValue("wPhoneCode", getValues('phoneCode'));
        }

    }, [currentSameAsPhone])

    useEffect(() => {
        if (currentDateOfBirth) {
            const currentDate: Date = new Date("2022-10-09T00:00:00+05:30"); // Calculate age as on program date;
            const age = Math.floor(differenceInYears(currentDate, currentDateOfBirth));
            currentDateOfBirth && setValue("age", age)
        }

    }, [currentDateOfBirth])
    const onSubmit = async (data: any) => {
        try {
            console.log('data', data);
            const userIdSting = userId as string;
            GTMPush(userIdSting, {
                event: 'nagablr_vp_pushed',
                virtual_path: '/nagablr_vp_personal_details_added'

            });
            scrollToNodeWithId("ad-card");
        } catch (error) {
            console.log("error in submit", error);
        }
    };
    return (
        <>
            <StepCardHolder
                heading={"Personal Details"}
                subHeading={"Please provide your personal details"}
                id={"pd-card"}
            >
                <div className="responsive-wrapper">
                    <TextField
                        hookFormRegister={register("firstName")}
                        id={"firstName"}
                        label={"First name*"}
                        name={"firstName"}
                        placeholderText={"First name"}
                        errorMessage={errors?.firstName?.message}
                        disabled={true}
                    />
                    <TextField
                        hookFormRegister={register("lastName")}
                        id={"lastName"}
                        label={"Last name*"}
                        name={"lastName"}
                        placeholderText={"Last name"}
                        errorMessage={errors?.lastName?.message}
                    />
                    <TextField
                        hookFormRegister={register("email")}
                        id={"email"}
                        label={"Email*"}
                        name={"email"}
                        placeholderText={"Email"}
                        errorMessage={errors?.email?.message}
                        disabled={true}
                    />
                    <Dropdown
                        hookFormRegister={register("gender")}
                        ariaFor={"gender"}
                        id={"gender"}
                        label={"Gender*"}
                        name={"gender"}
                        options={GENDERS_OPTIONS}
                        placeholderOption={"Select Gender"}
                        errorMessage={errors?.gender?.message}
                    />
                    {/* {currentGender === GENDERS.FEMALE && (
                        <SingleSelectQuestion
                            label={"Are you currently pregnant?"}
                            options={STANDARD_YES_NO_OPTIONS}
                            name={"isPregnant"}
                            hookFormRegister={register("isPregnant")}
                            errorMessage={errors?.isPregnant?.message}
                        />
                    )} */}

                    <Phone
                        label={"Phone*"}
                        id={"bi-phone"}
                        codeHookFormRegister={register("phoneCode")}
                        phoneHookFormRegister={register("phone")}
                        codeErrorMessage={errors?.phoneCode?.message}
                        phoneErrorMessage={errors?.phone?.message}
                        countryFilter={null}
                        disableCode={false}
                    />
                    <div>
                        <Phone
                            label={"WhatsApp Number"}
                            id={"bi-phone"}
                            codeHookFormRegister={register("wPhoneCode")}
                            phoneHookFormRegister={register("wPhone")}
                            codeErrorMessage={errors?.wPhoneCode?.message}
                            phoneErrorMessage={errors?.wPhone?.message}
                            countryFilter={null}
                        />
                        <Checkbox
                            id={'same-as-phone'}
                            label={"Same as phone"}
                            hookFormRegister={register('sameAsPhone')}
                            name={'sameAsPhone'}
                        />
                        {/* <div
                            onClick={() => {
                                setValue("wPhoneCode", getValues().phoneCode)
                                setValue("wPhone", getValues().phone)
                            }}
                            className='mt-2 text-sm underline text-blue-700 cursor-pointer'>
                            <strong>
                                Same as phone
                            </strong>
                        </div> */}
                    </div>
                    <DateOnly
                        label="Date of Birth*"
                        name="dob"
                        id="dob"
                        ariaFor="dob"
                        // hookFormRegister={register('dob', {
                        //     valueAsDate: true
                        // })}
                        control={control}
                        errorMessage={errors?.dob?.message}
                        placeholderOption={'dob'}
                    />
                    {ENVIRONMENT_TYPE !== "PROD" && <TextField
                        hookFormRegister={register("age", { valueAsNumber: true })}
                        id={"age"}
                        label={"Age*"}
                        name={"age"}
                        placeholderText={"age"}
                        errorMessage={errors?.age?.message}
                        dataType={"number"}
                        disabled={true}
                    />}

                </div>
                {isMinor && currentAge as number >= MINIMUM_AGE && (
                    <>
                        <div className="serif-medium text-base md:text-xl mt-6">
                            Guardian Proofs
                        </div>
                        <div className="sans-normal text-sm text-777Gray">
                            Below 18 years age Consent Scanned/Physical copy from Guardian and
                            their ID proofs
                        </div>
                        <div className="responsive-wrapper">
                            <div className="w-80">
                                <DownloadButton
                                    text="Download Consent Form"
                                    targetUrl={"https://d1p56q75p2nxev.cloudfront.net/G-Consent-Letter-Template.pdf"}
                                />
                            </div>
                            <div className="w-80">
                                <UploadButton
                                    text={"Signed Consent Form* (Max 2MB)"}
                                    name={"signedConsentForm"}
                                    control={control}
                                    defaultUrl={signedConsentFormUrl || undefined}
                                    label={'guardian consent form'}
                                    mimeTypes={"image/png, image/jpeg, application/pdf"}
                                    errorMessage={errors?.signedConsentForm?.message}
                                />
                            </div>
                            <div className="w-80">
                                <UploadButton
                                    text={"Guardian Photo* (Max 2MB)"}
                                    name={"guardianPhoto"}
                                    label={'guardian photo'}
                                    control={control}
                                    defaultUrl={guardianPhotoUrl || undefined}
                                    errorMessage={errors?.guardianPhoto?.message}
                                />
                            </div>
                            <div className="w-80">
                                <UploadButton
                                    text={"Guardian Address Poof* (Max 2MB)"}
                                    name={"guardianAddressProof"}
                                    label={'guardian address proof'}
                                    control={control}
                                    defaultUrl={guardianAddressProofUrl || undefined}
                                    errorMessage={errors?.guardianAddressProof?.message}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-10">
                            <Dropdown
                                hookFormRegister={register("guardianIdProofType")}
                                ariaFor={"guardianIdProofType"}
                                id={"guardianIdProofType"}
                                label={"ID Proof Type*"}
                                name={"guardianIdProofType"}
                                options={ID_PROOF_TYPES}
                                placeholderOption={"Select ID Proof Type"}
                                errorMessage={errors?.guardianIdProofType?.message}
                            />

                            <div className="mt-6">
                                <UploadButton
                                    text={"ID Proof* (Max 2MB)"}
                                    name={"guardianIdProofFile"}
                                    label={'guardian id proof'}
                                    defaultUrl={guardianIdProofFileUrl || undefined}
                                    control={control}
                                    errorMessage={errors?.guardianIdProofFile?.message}
                                />
                            </div>
                        </div>
                    </>
                )}
                <PrimaryButton
                    text={"NEXT"}
                    onClick={handleSubmit(onSubmit)}
                    id={`pp-card-submit`}
                />
                <HookFormDevTool control={control} />
            </StepCardHolder>
            <WatchFormAndUpdateGlobalState fileds={[
                {
                    formFieldName: "wPhoneCode",
                    stateFieldName: "userWPhoneCode"
                },
                {
                    formFieldName: "phoneCode",
                    stateFieldName: "userCurrentPhoneCode"
                },
                {
                    formFieldName: "phone",
                    stateFieldName: "userCurrentPhone"
                }
            ]} control={control} />
        </>
    )
}

export default forwardRef(PersonalDetails)