import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalState } from '../../store';
import { hideLoadingOverlay } from '../../utils';

const Blocked = () => {

  // const { form } = useGlobalState()

  const location: any = useLocation()
  const { message = `Thank you for your interest in the IE Retreat. You will receive an
  email communication shortly regarding next steps. Please check the
  same email address that you used to login.` } = location?.state ?? {}
  useEffect(() => {
    hideLoadingOverlay();
  }, []);

  return (
    <>
      <div
        className={
          'flex flex-col items-center max-w-xl md:mx-auto md:mt-32 mt-20 mx-4'
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-20 w-20"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#CD9548"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
          Namaskaram
        </div>
        <div dangerouslySetInnerHTML={{
          __html: message
        }} className={'sans-normal text-base text-center mx-auto whitespace-pre-wrap'} />


      </div>
    </>
  );
};

export default Blocked;
