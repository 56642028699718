import { captureException } from "@sentry/react";
import Snake from "components/Snake";
import { CONFIG } from "config";
import Blocked from "pages/Blocked";
import Form from "pages/Form";
import Payment from "pages/Payment";
import SubmissionTracker from "pages/SubmissionTracker";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Route, Routes, useNavigate } from "react-router-dom";
import { setGlobalState, useGlobalState } from "store";
import { getIPCountry } from "utils";
import { axiosBridged } from "utils/network";
import { DEPENDANT_STATUS, FORM_STEP, FORM_SUBMISSION_CANCELLED_MESSAGE, FORM_SUBMISSION_CLOSED_MESSAGE, HARDSHIP_STATUS, MINOR_STATUS, MULTI_BLOCK_MESSAGE, PRIMARY_PARTICIPANT_MESSAGE } from "../../constants";

const Home = () => {
    const navigate = useNavigate()
    const { userId } = useGlobalState();
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    useEffect(() => {

        const init = async () => {
            try {
                const ipCountry = await getIPCountry()
                // const ipCountry = "US"
                const resp: IAPIResp = await axiosBridged.get("/form", {
                    params: {
                        id: CONFIG.formId
                    }
                })
                // console.log("RESP ", resp)
                const existingSubmissionResp: IAPIResp = await axiosBridged.get("/submissions", {
                    params: {
                        formId: CONFIG.formId,
                        ssoId: userId
                    }
                })
                const batchesResp: IAPIResp = await axiosBridged.get("/batch/list", {
                    params: {
                        formId: CONFIG.formId
                    }
                })


                if (resp.payload && batchesResp.payload) {
                    setGlobalState({
                        form: resp.payload,
                        batches: batchesResp.payload,
                        existingSubmission: existingSubmissionResp.payload
                    })
                } else {
                    toast.error("Form not found")
                    throw new Error("Form not found")
                }


                if (existingSubmissionResp.payload) {
                    // if (existingSubmissionResp.payload?.dependentOf !== null) {
                    //     const targetBatch = batchesResp.payload
                    //         ?.find((batch: any) =>
                    //             batch.id === existingSubmissionResp.payload?.batch)
                    //     navigate('/blk', {
                    //         replace: true,
                    //         state: {
                    //             message: CO_PARTICIPANT_MESSAGE.replace("BATCH", targetBatch?.name)
                    //         }
                    //     });
                    //     return
                    // }
                    // else {
                    //     navigate('/blk', {
                    //         replace: true,
                    //         state: {
                    //             message: "A submission with this account already exists"
                    //         }
                    //     });
                    //     return
                    // }

                    const {
                        id: subId,
                        step,
                        minorStatus,
                        imsStatus,
                        paymentStatus,
                        batch,
                        dependantBlock,
                        hardShipStatus,
                        dependentOf,
                        status,
                        coParticipants,
                        data,
                        couponId,
                        couponData
                    } = existingSubmissionResp.payload as ISubmission
                    // if (dependentOf) {
                    //     if (status === FORM_SUBMISSION_STATUS.COMPLETED) {
                    //         navigate('/blk', {
                    //             replace: true,
                    //             state: {
                    //                 message:
                    //                     `BLOCK STATUS - MINOR-${minorStatus} - IMS-${imsStatus} - HARDSHIP-${hardShipStatus} - DEPENDANT-${dependantBlock}`
                    //             }
                    //         });
                    //         return
                    //     }
                    // }
                    switch (step) {
                        case FORM_STEP.RESUBMISSION: {
                            if (!couponId) {
                                navigate('/blk', {
                                    replace: true,
                                    state: {
                                        message: FORM_SUBMISSION_CLOSED_MESSAGE
                                    }
                                });
                                return;
                            }
                            const { countryOfResidence, nationality,
                                batch, preferredTranslationLanguage,
                                email, firstName, lastName, gender, dob, age, phone, phoneCode, wPhone, wPhoneCode, signedConsentForm, guardianPhoto, guardianAddressProof, guardianIdProofType, guardianIdProofFile,
                                pinCode, line1, city, state,
                                emergencyContactName, emergencyContactPhoneCode, emergencyContactPhone, emergencyContactRelation,
                                photo, addressProof, idProof, idProofType, passport, visa, CovidVaccinationStatus,
                                shipping
                            } = data;
                            setGlobalState({
                                countryOfResidence,
                                nationality,
                                couponId: null,
                                couponData: null,
                                batch,
                                shipping: shipping || null,
                                preferredTranslationLanguage,
                                dependentOf,
                                firstName, lastName, userEmail: email, gender, dob, age,
                                userCurrentPhoneCode: phoneCode, userCurrentPhone: phone, userWPhone: wPhone, userWPhoneCode: wPhoneCode,
                                signedConsentForm, guardianPhoto, guardianAddressProof, guardianIdProofType, guardianIdProofFile,
                                pinCode, line1, city, state,
                                emergencyContactName, emergencyContactPhoneCode, emergencyContactPhone, emergencyContactRelation,
                                photo, addressProof, idProof, idProofType, passport, visa, CovidVaccinationStatus,
                                isResubmission: true,
                                coParticipants: coParticipants ? coParticipants : null,
                            })
                            navigate("/naga", {
                                replace: true,
                                state: {
                                    ipCountry
                                }
                            });
                            break;
                        }
                        case FORM_STEP.PAYMENT: {
                            if (!couponId) {
                                navigate('/blk', {
                                    replace: true,
                                    state: {
                                        message: FORM_SUBMISSION_CLOSED_MESSAGE
                                    }
                                });
                                return;
                            }
                            // if (
                            //     batch === "bt_KP9o1Ga4THnXpyi" ||
                            //     batch === "bt_QIe8s9xjHXz0UmV"
                            // ) {
                            //     navigate("/blk", {
                            //         state: {
                            //             message: COUPLE_PAYMENT_BLOCK
                            //         },
                            //         replace: true
                            //     })
                            //     break;
                            // }
                            if (paymentStatus === "ERROR" && !dependentOf) {
                                try {
                                    await axiosBridged.post("/submissions/resubmission", undefined, {
                                        params: {
                                            subId
                                        }
                                    });
                                    window.location.reload();
                                    break;
                                } catch (err) {
                                    captureException(err, {
                                        tags: {
                                            subId,
                                            ssoId: userId,
                                            paymentStatus
                                        }
                                    })
                                }
                            }
                            navigate("/payment", {
                                replace: true
                            })
                            break;
                        }
                        case FORM_STEP.FINAL: {
                            navigate("/subTracker", {
                                replace: true
                            })
                            break;
                        }
                        case FORM_STEP.FORM: {
                            if (dependantBlock === DEPENDANT_STATUS.BLOCKED) {
                                const coParticipantnames = coParticipants?.map(x => `${x.profile?.firstName || ""} ${x.profile?.lastName || ""}`).join(', ') || "";
                                navigate('/blk', {
                                    replace: true,
                                    state: {
                                        message: PRIMARY_PARTICIPANT_MESSAGE.replace("{{coParticipants}}", coParticipantnames?.trim()?.length > 0 ? coParticipantnames : "your coparticipant")
                                    }
                                });
                                break;
                            }
                            if (
                                minorStatus === MINOR_STATUS.BLOCKED ||
                                imsStatus === MINOR_STATUS.BLOCKED ||
                                hardShipStatus === HARDSHIP_STATUS.BLOCKED ||
                                dependantBlock === DEPENDANT_STATUS.BLOCKED
                            ) {
                                navigate('/blk', {
                                    replace: true,
                                    state: {
                                        message: MULTI_BLOCK_MESSAGE
                                    }
                                });
                                break;
                            }
                            if (
                                minorStatus === MINOR_STATUS.REJECTED ||
                                imsStatus === MINOR_STATUS.REJECTED ||
                                hardShipStatus === HARDSHIP_STATUS.REJECTED ||
                                dependantBlock === DEPENDANT_STATUS.REJECTED
                            ) {
                                navigate('/blk', {
                                    replace: true,
                                    state: {
                                        message: FORM_SUBMISSION_CANCELLED_MESSAGE
                                    }
                                });
                                break;
                            }
                            if (!couponId) {
                                navigate('/blk', {
                                    replace: true,
                                    state: {
                                        message: FORM_SUBMISSION_CLOSED_MESSAGE
                                    }
                                });
                                return;
                                setGlobalState({
                                    couponId: null,
                                    countryOfResidence: ipCountry,
                                    couponData: null,
                                    nationality: null,
                                    batch,
                                    shipping: null,
                                    preferredTranslationLanguage: null,
                                    dependentOf,
                                    gender: null, dob: null, age: null,
                                    userCurrentPhoneCode: null, userCurrentPhone: null, userWPhone: null, userWPhoneCode: null,
                                    signedConsentForm: null, guardianPhoto: null, guardianAddressProof: null, guardianIdProofType: null, guardianIdProofFile: null,
                                    pinCode: null, line1: null, city: null, state: null,
                                    emergencyContactName: null, emergencyContactPhoneCode: null, emergencyContactPhone: null, emergencyContactRelation: null,
                                    photo: null, addressProof: null, idProof: null, idProofType: null, passport: null, visa: null, CovidVaccinationStatus: null,
                                    isResubmission: false,
                                    coParticipants: null,
                                })
                            }
                            else
                                setGlobalState({
                                    couponId,
                                    couponData,
                                    countryOfResidence: ipCountry,
                                    nationality: null,
                                    batch: batch && batch !== 'NONE' ? batch : null,
                                    preferredTranslationLanguage: null,
                                    dependentOf: null,
                                    shipping: null,
                                    gender: null, dob: null, age: null,
                                    userCurrentPhoneCode: null, userCurrentPhone: null, userWPhone: null, userWPhoneCode: null,
                                    signedConsentForm: null, guardianPhoto: null, guardianAddressProof: null, guardianIdProofType: null, guardianIdProofFile: null,
                                    pinCode: null, line1: null, city: null, state: null,
                                    emergencyContactName: null, emergencyContactPhoneCode: null, emergencyContactPhone: null, emergencyContactRelation: null,
                                    photo: null, addressProof: null, idProof: null, idProofType: null, passport: null, visa: null, CovidVaccinationStatus: null,
                                    isResubmission: false,
                                    coParticipants: null,
                                })
                            navigate("/naga", {
                                replace: true,
                                state: {
                                    ipCountry
                                }
                            })
                            break;
                        }
                        default:
                            break;
                    }
                }
                else {
                    navigate('/blk', {
                        replace: true,
                        state: {
                            message: FORM_SUBMISSION_CLOSED_MESSAGE
                        }
                    });
                    return;
                    setGlobalState({
                        countryOfResidence: ipCountry,
                        nationality: null,
                        batch: null,
                        couponId: null,
                        couponData: null,
                        preferredTranslationLanguage: null,
                        dependentOf: null,
                        shipping: null,
                        gender: null, dob: null, age: null,
                        userCurrentPhoneCode: null, userCurrentPhone: null, userWPhone: null, userWPhoneCode: null,
                        signedConsentForm: null, guardianPhoto: null, guardianAddressProof: null, guardianIdProofType: null, guardianIdProofFile: null,
                        pinCode: null, line1: null, city: null, state: null,
                        emergencyContactName: null, emergencyContactPhoneCode: null, emergencyContactPhone: null, emergencyContactRelation: null,
                        photo: null, addressProof: null, idProof: null, idProofType: null, passport: null, visa: null, CovidVaccinationStatus: null,
                        isResubmission: false,
                        coParticipants: null,
                    });
                    navigate("/naga", {
                        replace: true,
                        state: {
                            ipCountry,
                            // ipCountry: "US"
                        }
                    })
                }
            } catch (error: any) {
                console.log('error in init-->', error);
                captureException(error, {
                    tags: {
                        userId,
                    }
                });
                navigate("/", { replace: true }); // Going to home page on error, screen is blank
                alert(error?.message)
            }
        };
        (async () => {
            await init()
            setIsInitialized(true)
        })();

    }, [])

    return (
        <>
            {
                isInitialized ?
                    <Routes>
                        <Route path="/naga" element={<Form />} />

                        <Route path="/payment" element={<Payment />} />
                        <Route path="/subTracker" element={<SubmissionTracker />} />
                        <Route path="/blk" element={<Blocked />} />

                    </Routes>
                    :
                    <Snake />
            }
        </>
    )
}

export default Home