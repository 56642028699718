import { useEffect, useState } from "react";
import PaymentResponse from "../../components/PaymentResponse";

const GPMSResponse = () => {
  const [paymentData, setPaymentData] = useState<any>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const requestId = urlParams.get("requestId");
    const transactionId = urlParams.get("transactionId");
    const status = urlParams.get("status");
    const amount = urlParams.get("amount");
    const currency = urlParams.get("currency");
    const errorCode = urlParams.get("errorCode");
    const errorReason = urlParams.get("errorReason");
    const errorMsg = urlParams.get("errorMsg");

    setPaymentData({
      requestId,
      transactionId,
      status,
      amount,
      currency,
      errorCode,
      errorReason,
      errorMsg,
    });
  }, []);
  if (paymentData === null) {
    return <div>Processing...</div>;
  }
  if (paymentData?.requestId === null) {
    return <div>Processing...</div>;
  }
  return (
    <>
      <PaymentResponse
        status={"INPROGRESS"}
        //status={paymentData?.status}
        transactionId={paymentData?.transactionId}
      />
    </>
  );
};

export default GPMSResponse;
