import { useRef } from 'react';
import { COUNTRY_DATA } from '../../utils/countryData';
interface phoneProps {
  label?: string;
  id: string;
  codeErrorMessage: any;
  phoneErrorMessage: any;
  codeHookFormRegister?: object;
  phoneHookFormRegister?: object;
  disabled?: boolean;
  disableCode?: boolean;
  countryFilter: string[] | null;
}
const Phone = (props: phoneProps) => {
  const {
    label = 'Phone',
    id,
    codeErrorMessage,
    phoneErrorMessage,
    codeHookFormRegister = {},
    phoneHookFormRegister = {},
    disabled = false,
    disableCode = false,
    countryFilter = null,
  } = props;

  let sortByDialCode = COUNTRY_DATA.map((a) => ({
    ...a,
    strippedDialCode: parseInt(a.dialCode.replace(/\+/, '').trim(), 10),
  })).sort((a, b) => a.strippedDialCode - b.strippedDialCode);

  const india = sortByDialCode.find((a) => a.countryCode === 'IN');
  if (india) {
    sortByDialCode = [
      india,
      ...sortByDialCode.filter((a) => a.countryCode !== 'IN'),
    ];
  }

  const dialCodeOptions = useRef(
    countryFilter === null
      ? sortByDialCode.map((a) => ({
          label: `${a.dialCode} - ${a.countryCode}`.replace(/\+/, ''),
          value: a.dialCode,
        }))
      : sortByDialCode
          .filter((a) => countryFilter.includes(a.countryCode))
          .map((a) => ({
            label: `${a.dialCode} - ${a.countryCode}`.replace(/\+/, ''),
            value: a.dialCode,
          }))
  );
  return (
    <div className={'flex flex-col mt-6'} id={id}>
      <div className={'text-444Black sans-normal text-sm'}>{label}</div>
      <div className="flex flex-row w-80">
        <select
          disabled={disabled || disableCode}
          className="mt-1 mr-2 block w-32  h-10 py-2 px-4 border border-slate-400 bg-white rounded-sm shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
          name={'code'}
          {...codeHookFormRegister}
        >
          <option value="" disabled hidden>
            {'Code'}
          </option>
          {dialCodeOptions.current.map((a, i) => (
            <option value={a.value} key={`${i}-${a.value}`}>
              {a.label}
            </option>
          ))}
        </select>
        <input
          disabled={disabled}
          type={'number'}
          className={`mt-1 w-40 h-10 ${
            phoneErrorMessage ? 'ring-red-500' : ''
          }  rounded-sm border-transparent flex-1 appearance-none border border-slate-400  py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent`}
          name={'phone'}
          placeholder={'Phone'}
          {...phoneHookFormRegister}
        />
      </div>
      {(codeErrorMessage || phoneErrorMessage) && (
        <p className="text-base font-semibold text-red-500 ">{'Enter valid phone number'}</p>
      )}
    </div>
  );
};

export default Phone;
