import { yupResolver } from "@hookform/resolvers/yup";
import Checkbox from "components/Checkbox";
import Dropdown from "components/DropDown";
import PrimaryButton from "components/PrimaryButton";
import StepCardHolder from "components/StepCardHolder";
import TextField from "components/TextField";
import { CONFIG } from "config";
import useForceRender from "customHooks/useForceRender";
import AttendanceOptions from "pages/Form/components/AttendanceOptions";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Navigate, useSearchParams } from "react-router-dom";
import { resetGlobalState, setGlobalState, useGlobalState } from "store";
import { getDetailsOfPinCode, hideLoadingOverlay, showLoadingOverlay } from "utils";
import { axiosBridged } from "utils/network";
import { COUNTRY_DATA } from '../../utils/countryData'
import validationSchema from './validations';
import successIcon from '../../assets/images/success.svg';

function Page() {
    const [isInit, setIsInit] = useState<boolean>(false);
    const { userId, existingSubmission, batches, userEmail } = useGlobalState();
    const attendanceOptionsRef = useRef<any>(null)
    const getRef = (type: string) => {
        switch (type) {
            case "ATTENDANCE_OPTIONS": return attendanceOptionsRef.current;
            default:
                break;
        }
    }
    const refresh = useForceRender();
    const countryOptions = useRef(
        COUNTRY_DATA.filter(x => x.countryCode === 'IN').map((a) => ({
            label: a.countryName,
            value: a.countryCode,
        }))
    );
    const { register, handleSubmit, watch, setValue, trigger, getValues, formState: { errors } } = useForm({
        defaultValues: {
            sameAsBilling: "0",
            line1: '',
            city: '',
            state: '',
            pinCode: '',
            country: 'IN'
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })

    useEffect(() => {
        (async () => {
            try {
                const existingSubmissionResp: IAPIResp = await axiosBridged.get("/submissions", {
                    params: {
                        formId: CONFIG.formId,
                        ssoId: userId
                    }
                })
                const batchesResp: IAPIResp = await axiosBridged.get("/batch/list", {
                    params: {
                        formId: CONFIG.formId
                    }
                });
                if (existingSubmissionResp.payload) {
                    setGlobalState({
                        existingSubmission: existingSubmissionResp.payload
                    })
                } else {
                    setGlobalState({
                        existingSubmission: null
                    })
                }
                if (batchesResp.payload) {
                    setGlobalState({
                        batches: batchesResp.payload
                    })
                }
                else {
                    throw new Error('Cannot pull batches');
                }
                setIsInit(true);
            } catch (err: any) {
                toast.error(err.response?.data?.message || err.message || 'Something went wrong')
            }
        })();
    }, [userId])
    const sameAsBilling = watch('sameAsBilling');
    const currentPinCode = watch('pinCode');
    const currentCountry = watch('country');
    useEffect(() => {
        if (Boolean(parseInt(sameAsBilling))) {
            setValue('pinCode', existingSubmission?.data?.pinCode)
            setValue('line1', existingSubmission?.data?.line1)
            setValue('city', existingSubmission?.data?.city)
            setValue('state', existingSubmission?.data?.state)
        }
    }, [sameAsBilling]);
    useEffect(() => {
        const getPinCodeDetails = async () => {
            try {
                const resp = await getDetailsOfPinCode(
                    currentCountry,
                    currentPinCode
                );
                if (Object.keys(resp).length <= 0)
                    return;
                const { defaultcity, state } = resp;
                setValue('city', defaultcity);
                setValue('state', state);
                await trigger();
            } catch (error) {
                console.log('error in getPinCodeDetails-->', error);
                throw error;
            }
        };
        if (currentCountry !== undefined && currentPinCode) {
            if (currentCountry === 'IN' && currentPinCode.length === 6)
                getPinCodeDetails();
            else if (currentCountry !== 'IN' && currentPinCode.length >= 5)
                getPinCodeDetails();
        }
    }, [currentPinCode, currentCountry]);
    const onSubmit = useCallback(async () => {
        try {
            showLoadingOverlay();
            const cers = await trigger();
            if (cers === false) {
                throw new Error('Fill mandatory fields in address details')
            }
            await axiosBridged.put('/submissions/shippingAddress', {
                ...getValues(),
                subId: existingSubmission?.id
            })
            // @ts-ignore
            const newSubmission = Object.assign({}, existingSubmission);
            newSubmission.data['shipping'] = {
                ...getValues(),
                subId: newSubmission?.id
            };
            setGlobalState({
                existingSubmission: newSubmission
            });
            refresh();
            hideLoadingOverlay()
        }
        catch (err: any) {
            hideLoadingOverlay()
            toast.error(err.response?.data?.message || err?.message || 'Unable to update shipping address')

        }
    }, [existingSubmission]);
    if (!isInit)
        return null;
    if (existingSubmission === null) {
        return <div className={'flex flex-col items-center max-w-2xl md:mx-auto mx-4 mt-20'}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-20 w-20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#CD9548"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
            </svg>
            <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
                Registration Not Found
            </div>
            <div className="sans-normal text-center pt-8 text-base">
                The logged in email <strong>{userEmail}</strong> is not registered. Please login with the email address you entered at the time of registration.
                <br />
                <br />
                Pranam,
                <br />
                Linga Bhairavi Team
            </div>
            <div className="w-48">
                <PrimaryButton
                    text={"Change Email"}
                    onClick={() => {
                        localStorage.setItem('redirectToShipping', "1");
                        window.location.replace('/logout');
                    }}
                    id={`pp-card-submit`}
                />
            </div>
        </div>
    }
    if (existingSubmission.isOverseas) {
        return <div className={'flex flex-col items-center max-w-2xl md:mx-auto mx-4 mt-20'}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-20 w-20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#CD9548"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
            </svg>
            <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
                Namaskaram
            </div>
            <div className="sans-normal text-center pt-8 text-base">
                Please note that the address reconfirmation request to receive the Naga Pratishtha wristband by courier is only for Indian participants residing in India. <br /><br />For overseas participants, the check-in details will be emailed to you.
                <br />
                <br />
                Pranam,
                <br />
                Linga Bhairavi Team
            </div>
        </div>
    }
    if (existingSubmission.dependentOf) {
        return <div className={'flex flex-col items-center max-w-2xl md:mx-auto mx-4 mt-20'}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-20 w-20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#CD9548"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
            </svg>
            <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
                Namaskaram
            </div>
            <div className="sans-normal text-center pt-8 text-base">
                We have requested the primary participant to log in and confirm their mailing address.
                <br />
                <br />
                Pranam,
                <br />
                Linga Bhairavi Team
            </div>
        </div>
    }
    return <div className="mx-1 mb-12">
        {
            existingSubmission?.data?.shipping &&
            <div className={'flex flex-col items-center max-w-2xl md:mx-auto mx-4 mt-20'}>
                <img src={successIcon} alt={'success'} className={'w-20 h-20'} />
                <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
                    Shipping Address Updated
                </div>
                <div className="sans-normal text-center py-8 text-base">
                    Your shipping address {existingSubmission?.data?.shipping?.subId ? "is updated" : "has already been updated"}. If you would like to change the submitted address, please contact us on <a className="underline" href="tel:+918300053111">8300053111</a> or <a className="underline" href="mailto:info.iycbangalore@lingabhairavi.org">info.iycbangalore@lingabhairavi.org</a> for assistance.
                </div>
            </div>
        }
        <StepCardHolder
            heading={"Personal Details"}
            id={"ao-card"}
        >
            <div className="flex flex-row justify-between mt-5">
                <div className="serif-medium text-base">Registered Bay</div>
                <div className="sans-normal text-base">{batches?.find(x => x.id === existingSubmission?.batch)?.name}</div>
            </div>
            <div className="flex flex-row justify-between mt-5">
                <div className="serif-medium text-base">Email </div>
                <div className="sans-normal text-base">{`${existingSubmission?.data?.email}`}</div>
            </div>
            <div className="flex flex-row justify-between mt-5">
                <div className="serif-medium text-base">Name </div>
                <div className="sans-normal text-base">{`${existingSubmission?.data?.firstName} ${existingSubmission?.data?.lastName}`}</div>
            </div>
            <div className="flex flex-row justify-between mt-5">
                <div className="serif-medium text-base">Phone</div>
                <div className="sans-normal text-base">{`${existingSubmission?.data?.phoneCode} ${existingSubmission?.data?.phone}`}</div>
            </div>
            <div className="flex flex-col mt-10 space-y-2">
                <div>
                    <div className="serif-medium text-lg">Billing Address</div>
                    <div className="w-full h-px mt-4 bg-gray-200" />
                </div>
                <div className="sans-normal text-base pt-5">{`${existingSubmission?.data?.line1},`}</div>
                <div className="sans-normal text-base">{`${existingSubmission?.data?.city},`}</div>
                <div className="sans-normal text-base">{`${existingSubmission?.data?.state},`}</div>
                <div className="sans-normal text-base">{`${COUNTRY_DATA.find(x => x.countryCode === existingSubmission?.data?.countryOfResidence)?.countryName} - ${existingSubmission?.data?.pinCode}`}</div>
            </div>
            <div className="flex flex-col mt-10 space-y-2">
                <div className="mb-5">
                    <div className="serif-medium text-lg">Shipping Address</div>
                    <div className="w-full h-px mt-4 bg-gray-200" />
                </div>
                {!existingSubmission?.data?.shipping &&
                    <>
                        <Checkbox
                            id={'accept-non-refund-and-conditions'}
                            mode={'RADIO'}
                            label={
                                <>
                                    Do you want to ship Naga Pratishta wristband to above address?
                                </>
                            }
                            hookFormRegister={register('sameAsBilling')}
                            name={'sameAsBilling'}
                        />
                        <div className="responsive-wrapper">
                            <Dropdown
                                label="Country*"
                                hookFormRegister={register('country')}
                                ariaFor={'countryOfResidence'}
                                id={'countryOfResidence'}
                                name={'countryOfResidence'}
                                options={countryOptions.current}
                                placeholderOption={'Country of Residence '}
                                errorMessage={errors?.country?.message}
                            />
                            <TextField
                                hookFormRegister={register('pinCode')}
                                id={'pinCode'}
                                label={'Pincode *'}
                                name={'pinCode'}
                                placeholderText={'Pincode/Zipcode'}
                                errorMessage={errors?.pinCode?.message}
                            // dataType={'number'}
                            />
                            <TextField
                                hookFormRegister={register('line1')}
                                id={'line1'}
                                label={'Address *'}
                                name={'line1'}
                                placeholderText={'Address'}
                                errorMessage={errors?.line1?.message}
                            />

                            <TextField
                                hookFormRegister={register('city')}
                                id={'City'}
                                label={'City*'}
                                name={'city'}
                                placeholderText={'City/ Town/ District'}
                                errorMessage={errors?.city?.message}
                            />
                            <TextField
                                hookFormRegister={register('state')}
                                id={'state'}
                                label={'State*'}
                                name={'state'}
                                placeholderText={'State/Province'}
                                errorMessage={errors?.state?.message}
                            />
                        </div>
                        <div>
                            <PrimaryButton
                                text={'SUBMIT'}
                                onClick={handleSubmit(onSubmit)}
                                id={`pp-card-submit`}
                            />
                        </div>
                    </>}
                {
                    existingSubmission?.data?.shipping &&
                    <>
                        <div className="sans-normal text-base">{`${existingSubmission?.data?.shipping?.line1},`}</div>
                        <div className="sans-normal text-base">{`${existingSubmission?.data?.shipping?.city},`}</div>
                        <div className="sans-normal text-base">{`${existingSubmission?.data?.shipping?.state},`}</div>
                        <div className="sans-normal text-base">{`${COUNTRY_DATA.find(x => x.countryCode === existingSubmission?.data?.shipping?.country)?.countryName} - ${existingSubmission?.data?.shipping?.pinCode}`}</div>
                    </>

                }
            </div>
        </StepCardHolder >
    </div >;
}

export default Page;