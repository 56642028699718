import { yupResolver } from '@hookform/resolvers/yup';
import Dropdown from 'components/DropDown';
import PrimaryButton from 'components/PrimaryButton';
import StepCardHolder from 'components/StepCardHolder';
import { FORM_COMPONENT_TYPE } from '../../../../constants';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { GTMPush, scrollToNodeWithId } from 'utils';
import { COUNTRY_DATA } from 'utils/countryData';
import validationSchema from './validations';
import { getGlobalState, setGlobalState, useGlobalState } from 'store';
import HookFormDevTool from 'components/HookFormDevTool';
interface IProps {
    getRef: any,
}
const CountryDetails = (props: IProps, ref: any) => {
    const { getRef } = props
    const { countryOfResidence, nationality, userId } = useMemo(() => ({ ...getGlobalState() }), []);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        getValues,
        control,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            countryOfResidence,
            nationality: nationality || ''
        },
        mode: 'onBlur',
    });

    useImperativeHandle(ref, () => ({
        getData: async () => {
            try {
                const cers = await trigger();
                if (Object.keys(errors).length > 0 || cers === false) {
                    scrollToNodeWithId('cn-card');
                    throw new Error('Select country and nationality')
                }
                const targetData = { ...getValues() };
                return targetData;
            } catch (error) {
                console.log('error in setting');
                throw error;
            }
        },
    }));

    const countryOptions = useRef(
        COUNTRY_DATA.map((a) => ({
            label: a.countryName,
            value: a.countryCode,
        }))
    )
    const countryOptionsForNationality = useRef(
        [{ label: 'Indian', value: 'IN' },
        ...COUNTRY_DATA.filter(a => a.countryCode !== "IN").map((a) => ({
            label: a.countryName,
            value: a.countryCode,
        }))
        ]

    )
    const currentCountryOfResidence = watch('countryOfResidence');
    const currentNationality = watch("nationality")
    const isIndian = currentCountryOfResidence === 'IN';

    countryOptions.current =
        COUNTRY_DATA
            // .filter(x => x.countryCode !== "IN")
            .map((a) => ({
                label: a.countryName,
                value: a.countryCode,
            }));
    useEffect(() => {
        if (currentCountryOfResidence)
            setGlobalState({
                countryOfResidence: currentCountryOfResidence
            })
    }, [currentCountryOfResidence])

    useEffect(() => {
        if (currentNationality)
            setGlobalState({
                nationality: currentNationality
            })
    }, [currentNationality])

    useEffect(() => {
        if (currentCountryOfResidence && currentNationality) {
            getRef(FORM_COMPONENT_TYPE.DOCUMENT_DETAILS)
                .setIndian(currentCountryOfResidence, currentNationality)
            getRef(FORM_COMPONENT_TYPE.ATTENDANCE_OPTIONS)
                .setIndian(currentCountryOfResidence, currentNationality)
        }

    }, [currentCountryOfResidence, currentNationality])

    const onSubmit = async (data: any) => {
        try {
            scrollToNodeWithId("ao-card");
            GTMPush(userId!, {
                event: 'nagablr_vp_pushed',
                virtual_path: '/nagablr_vp_country_nationality_added'
            });
        } catch (error) {
            console.log("error in submit", error);
        }
    };

    return (
        <>
            <StepCardHolder
                heading={"Country and Nationality"}
                subHeading={"Please provide your nationality and country of residence"}
                id={"cn-card"}
                fillFunction={() => {
                    setValue("nationality", "IN")
                    setValue("countryOfResidence", "IN")
                }}
            >
                <div className="responsive-wrapper">
                    <Dropdown
                        label="Nationality*"
                        hookFormRegister={register('nationality')}
                        ariaFor={'nationality'}
                        id={'nationality'}
                        name={'nationality'}
                        options={countryOptionsForNationality.current}
                        placeholderOption={'Nationality '}
                        errorMessage={errors?.nationality?.message}
                    // disabled={isIpIndian}
                    />
                    <Dropdown
                        label="Country of Residence*"
                        hookFormRegister={register('countryOfResidence')}
                        ariaFor={'countryOfResidence'}
                        id={'countryOfResidence'}
                        name={'countryOfResidence'}
                        options={countryOptions.current}
                        placeholderOption={'Country of Residence '}
                        errorMessage={errors?.countryOfResidence?.message}
                    // disabled={isIpIndian}
                    />
                </div>
                <PrimaryButton
                    text={"NEXT"}
                    onClick={handleSubmit(onSubmit)}
                    id={`pp-card-submit`}
                />
            </StepCardHolder>
            <HookFormDevTool control={control} />
        </>
    )
}

export default forwardRef(CountryDetails)