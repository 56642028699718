// Can be DEV,UAT,LOCAL_HOST,PROD
export const ENVIRONMENT_TYPE: string = import.meta.env.MODE;
interface configInterface {
  baseApiUrl: string;
  globalApiUrl: string;
  ssoUrl: string;
  loginHash: string;
  backOfficeOrigin: string;
  pmsUrl: string;
  gpmsRequestURL?: string;
  formId: string;
}

interface mapInterface {
  LOCAL_HOST: configInterface;
  UAT: configInterface;
  PROD: configInterface;
}

const LOCAL_HOST_CONFIG: configInterface = {
  baseApiUrl: 'http://localhost:4049',
  globalApiUrl: '',
  ssoUrl: 'https://uat-sso.isha.in',
  loginHash: 'd2239574f2d4564e0afe43b73d4b5373d6af9c3d7a345d3cd7ef6c14503ee9a7',
  backOfficeOrigin: 'http://localhost:3006',
  pmsUrl: 'https://uat-profile.isha.in/services/pms',
  gpmsRequestURL:
    'https://uat-payment.lingabhairavi.org/api/gprs/payment-request',
  formId: "frm_PUNG5T04HYyzjpI",
};

const UAT_CONFIG: configInterface = {
  baseApiUrl: 'https://naga-uat-api.sadhguru.org',
  globalApiUrl: '',
  ssoUrl: 'https://uat-sso.isha.in',
  loginHash: '3e008897aee6d38fef9a4c48469469e1496b26524bf16351dc2bd466a0d7b25a',
  backOfficeOrigin: 'https://uat-bolb.netlify.app',
  pmsUrl: 'https://uat-profile.isha.in/services/pms',
  gpmsRequestURL:
    'https://uat-payment.lingabhairavi.org/api/gprs/payment-request',
  formId: "frm_PUNG5T04HYyzjpI",
};

const PROD_CONFIG: configInterface = {
  baseApiUrl: 'https://iycblr-naga-api.sadhguru.org',
  globalApiUrl: '',
  ssoUrl: 'https://ishalogin.sadhguru.org',
  loginHash: 'ab2b0b5c1c7da9d867928412b41c328669c68dcda535f0761b1a7de5d73bb67a',
  backOfficeOrigin: 'https://uat-bolb.netlify.app',
  pmsUrl: 'https://pms.sadhguru.org/services/pms/',
  gpmsRequestURL: 'https://payment.lingabhairavi.org/api/gprs/payment-request',
  formId: "frm_PUNG5T04HYyzjpI",
};

const CONFIG_MAP: mapInterface = {
  LOCAL_HOST: LOCAL_HOST_CONFIG,
  UAT: UAT_CONFIG,
  PROD: PROD_CONFIG,
};

const selector = () => {
  switch (import.meta.env.MODE) {
    case 'LOCAL_HOST':
      return CONFIG_MAP.LOCAL_HOST;
    case 'UAT':
      return CONFIG_MAP.UAT;
    case 'PROD':
      return CONFIG_MAP.PROD;
    default:
      return CONFIG_MAP.UAT;
  }
};

export const CONFIG = selector();
