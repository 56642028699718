import { yupResolver } from '@hookform/resolvers/yup';
import { REGISTRATION_COUPON_CHANNELS } from 'constants';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useGlobalState } from 'store';
import { GTMPush } from 'utils';
import Checkbox from '../../../../components/Checkbox';
import HookFormDevTool from '../../../../components/HookFormDevTool';
import PrimaryButton from '../../../../components/PrimaryButton';
import StepCardHolder from '../../../../components/StepCardHolder';
import validationSchema from './validations';

const Declarations = (props: { onSave: any }) => {
  const { userId, countryOfResidence, nationality, batch, couponId, couponData } = useGlobalState();
  const shouldAgreeForNonRefund = (
    (
      (countryOfResidence && countryOfResidence !== "IN")
      ||
      (nationality && nationality !== "IN")
    )
    &&
    (batch === "bt_2ZlmVLdNh2tJiQP" || batch === "bt_NSRqdyXIUgK29V4")
    &&
    (!couponId
      ||
      (couponData?.channel !== REGISTRATION_COUPON_CHANNELS.COMPLIMENTARY
        &&
        couponData?.channel !== REGISTRATION_COUPON_CHANNELS.DONOR
        &&
        couponData?.channel !== REGISTRATION_COUPON_CHANNELS.TRANSFER
      )

    )
  );
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const onSubmit = async (data: any) => {
    try {
      const {
        agreedToProgramGuidelines,
        agreedToTermsAndConditions
      } = data;
      if (agreedToProgramGuidelines && (!shouldAgreeForNonRefund || agreedToTermsAndConditions)) {
        props.onSave();
        GTMPush(userId as string, {
          event: 'nagablr_vp_pushed',
          virtual_path: '/nagablr_vp_proceed_clicked'
        });
      } else {
        toast.error('Please agree to all the terms and conditions');
      }
    } catch (error) {
      console.log('error in submit', error);
    }
  };

  return (
    <>
      <StepCardHolder
        heading={'Declarations'}
        subHeading={'Please accept the terms and conditions'}
        id={'d-card'}
      >
        <Checkbox
          id={'have-read-terms-and-conditions'}
          label={
            <>
              I agree and accept the terms & conditions including the refund and cancellation policy mentioned on the
              {' '}
              <a
                className={'text-blue-800 underline'}
                target={'_blank'}
                href={
                  'https://isha.sadhguru.org/in/en/events/special-events/naga-pratishtha/terms-and-conditions'
                }
              >

                isha.sadhguru.org
              </a>
              {' '}
              website
            </>
          }
          hookFormRegister={register('agreedToProgramGuidelines')}
          name={'agreedToProgramGuidelines'}
        />
        {
          shouldAgreeForNonRefund
          &&
          <Checkbox
            id={'accept-non-refund-and-conditions'}
            label={
              <>
                I agree and understand that Padma and MahaPadma program fee is non-refundable
              </>
            }
            hookFormRegister={register('agreedToTermsAndConditions')}
            name={'agreedToTermsAndConditions'}
          />
        }
        <PrimaryButton
          text={'Proceed'}
          onClick={handleSubmit(onSubmit)}
          id={`pp-card-submit`}
        />
        {/* <HookFormDevTool control={control} /> */}
      </StepCardHolder>
    </>
  );
};

export default Declarations;
