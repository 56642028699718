interface textFieldProps {
  label?: string;
  placeholderText: string;
  name: string;
  id: string;
  dataType?: string;
  errorMessage: any;
  hookFormRegister?: object;
  disabled?: boolean;
}

const TextField = (props: textFieldProps) => {
  const {
    label = null,
    placeholderText,
    errorMessage,
    name,
    hookFormRegister = {},
    dataType = 'text',
    disabled = false,
  } = props;

  return (
    <>
      <div className="flex flex-col mt-6 relative ">
        {<div className="text-444Black sans-normal text-sm">{label}</div>}
        <input
          type={dataType}
          id="on-error-email"
          className={`mt-1 w-80 h-12 ${errorMessage ? 'ring-red-500' : ''
            }  rounded-sm border-transparent flex-1 appearance-none border border-slate-400  py-2 px-4 ${disabled ? 'bg-gray-200' : 'bg-white'
            }  text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent`}
          name={name}
          placeholder={placeholderText}
          disabled={disabled}
          {...hookFormRegister}
          pattern={'[0-9]+'}
        />

        {errorMessage && (
          <>
            <p className="text-base font-semibold text-red-500">{errorMessage}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 absolute  right-2 bottom-6 "
              fill="none"
              viewBox="0 0 24 24"
              stroke="red"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </>
        )}
      </div>
    </>
  );
};

export default TextField;
