import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
interface IProps {
    label: string;
    ariaFor: string;
    placeholderOption: string;
    name: string;
    id: string;
    errorMessage?: any;
    control?: any;
}

const DateOnly = (props: IProps) => {
    const {
        label,
        ariaFor,
        placeholderOption,
        name,
        id,
        errorMessage = null,
        control = {},
    } = props;
    return (
        <>
            <label
                className="text-gray-700 flex flex-col relative  mt-6"
                htmlFor={ariaFor}
            >
                <div className={'text-444Black sans-normal text-sm'}>{label}</div>
                {/* <input
                    type="date"
                    id={id}
                    pattern={"dd-mm-yyyy"}
                    onKeyDown={(e) => e.preventDefault()}
                    name={ariaFor}
                    placeholder={placeholderOption}
                    {...hookFormRegister}
                    className="mt-1 block w-80 h-11 py-2 px-4 border border-gray-300 bg-white rounded-sm shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                /> */}
                {/* <DatePicker
                    {...hookFormRegister}
                    //   selected={startDate}
                    //   onChange={(date) => setStartDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                /> */}
                <div className="mt-1 block w-full h-full py-2 px-4 border border-gray-300 bg-white rounded-sm shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500">
                    <Controller
                        control={control}
                        name={name}
                        render={({ field }) => (
                            <DatePicker
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat={"dd/MM/yyyy"}
                                placeholderText={label}
                                onChange={(date: any) => field.onChange(date)}
                                maxDate={new Date("2022-10-09T00:00:00+05:30")}
                                selected={field.value}
                            />
                        )}
                    />
                </div>
                {errorMessage && (
                    <>
                        <p className="text-base font-semibold text-red-500">{errorMessage}</p>
                    </>
                )}
            </label>
        </>
    );
}

export default DateOnly