import { getGlobalState } from "store";
import { object, string, ValidationError } from "yup"
const validationSchema = object().shape({
    emergencyContactName: string().required().matches(/^[a-zA-Z ]+$/, "Enter valid name"),
    emergencyContactPhone: string().required().test("phone-check", "Enter valid phone number", function (value: string | undefined): boolean {
        if (value) {
            const { emergencyContactPhoneCode } = getGlobalState();
            if (emergencyContactPhoneCode === '+91')
                return value.length === 10;
            else
                return value.length >= 7 && value.length <= 14
        };
        return false;
    // }).test("check-user-same-phone", "Emergency number cannot be same as participant number", function (value: string | undefined): ValidationError | boolean {
    //     if (value) {
    //         const { userCurrentPhone, userCurrentPhoneCode, emergencyContactNoCode } = getGlobalState();
    //         if (emergencyContactNoCode === userCurrentPhoneCode && value === userCurrentPhone)
    //             return true
    //         else new ValidationError("Emergency number cannot be same as participant number");
    //     }
    //     return false;
    }),
    emergencyContactPhoneCode: string().required(),
    emergencyContactRelation: string().required(),
    // emergencyContactEmail: string().email().required(),
})

export default validationSchema