import SectionHeader from "components/SectionHeader";
import { CONFIG } from "config";
import { FORM_COMPONENT_TYPE, FORM_PAYMENT_STATUS } from "../../constants";
import { useRef } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalState } from "store";
import { hideLoadingOverlay, showLoadingOverlay } from "utils";
import { axiosBridged } from "utils/network";
import AddressDetails from "./components/AddressDetails";
import AttendanceOptions from "./components/AttendanceOptions";
import CoParticipants from "./components/CoParticipants";
import CountryDetails from "./components/CountryDetails";
import Declarations from "./components/Declarations";
import DocumentUpload from "./components/DocumentUpload";
import EmergencyContactInformation from "./components/EmergencyContactInformation";
import PersonalDetails from "./components/PersonalDetails";
import * as Sentry from "@sentry/react";
import { captureException } from "@sentry/react";

const Form = () => {
    const navigate = useNavigate();
    const location: any = useLocation()
    const { userId, existingSubmission, isResubmission, couponId } = useGlobalState()
    const { ipCountry = null } = location?.state ?? {}
    const countryDetailsRef = useRef<any>(null)
    const attendanceOptionsRef = useRef<any>(null)
    const personalDetailsRef = useRef<any>(null)
    const addressDetailsRef = useRef<any>(null)
    const emergencyContactInformationRef = useRef<any>(null)
    const documentUploadRef = useRef<any>(null)
    const coParticipantsDetailsRef = useRef<any>(null)


    const getRef = (type: string) => {
        switch (type) {
            case "COUNTRY_DETAILS": return countryDetailsRef.current;
            case "ATTENDANCE_OPTIONS": return attendanceOptionsRef.current;
            case "PERSONAL_DETAILS": return personalDetailsRef.current;
            case "ADDRESS_DETAILS": return addressDetailsRef.current;
            case "EMERGENCY_DETAILS": return emergencyContactInformationRef.current;
            case FORM_COMPONENT_TYPE.CO_PARTICIPANT_DETAILS: return coParticipantsDetailsRef.current;
            case "DOCUMENT_DETAILS": return documentUploadRef.current;
            default:
                break;
        }
    }

    const saveForm = async () => {
        try {
            showLoadingOverlay();
            let finalData: any = {
            };
            try {
                const countryData = await countryDetailsRef.current?.getData()
                const attendanceDetails = await attendanceOptionsRef.current?.getData()
                const personalDetails = await personalDetailsRef.current?.getData()
                const addressDetails = await addressDetailsRef.current?.getData()
                const emergencyDetailsDetails = await emergencyContactInformationRef.current?.getData()
                const documentDetails = await documentUploadRef.current?.getData()
                const coParticipantDetails = await coParticipantsDetailsRef.current?.getData()
                const shipping = countryData?.countryOfResidence === 'IN' && countryData?.nationality === 'IN' ?
                    {
                        line1: addressDetails?.shippingLine1,
                        city: addressDetails?.shippingCity,
                        state: addressDetails?.shippingState,
                        pinCode: addressDetails?.shippingPinCode,
                        country: addressDetails?.shippingCountry,
                    }
                    :
                    undefined;
                delete addressDetails?.shippingLine1;
                delete addressDetails?.shippingCity;
                delete addressDetails?.shippingState;
                delete addressDetails?.shippingPinCode;
                delete addressDetails?.shippingCountry;
                finalData = {
                    ...countryData,
                    ...attendanceDetails,
                    ...personalDetails,
                    ...addressDetails,
                    shipping: shipping,
                    ...emergencyDetailsDetails,
                    ...documentDetails,
                    ...coParticipantDetails
                }
            } catch (err: any) {
                toast.error(err?.message);
                hideLoadingOverlay();
                throw err;
            }
            try {
                if (existingSubmission?.dependentOf) {
                    finalData.depSubId = existingSubmission.id
                }
                showLoadingOverlay();
                if (isResubmission) {
                    await axiosBridged.put("/submissions", {
                        ...finalData,
                        subId: existingSubmission?.id,
                        incomingUtms: localStorage.getItem("ip-utms"),
                        formId: CONFIG.formId,
                        ssoId: userId,
                    }, { timeout: 20000 });
                } else
                    await axiosBridged.post(existingSubmission?.dependentOf ? "/submissions/dependant" : "/submissions", {
                        ...finalData,
                        incomingUtms: localStorage.getItem("ip-utms"),
                        couponId,
                        formId: CONFIG.formId,
                        ssoId: userId,
                    }, { timeout: 20000 })
                window.location.replace("/")
            } catch (err: any) {
                captureException(err, {
                    tags: {
                        userId
                    }
                });
                if (err?.response?.data?.message)
                    toast.error(`Submission Error - ${err?.response?.data?.message}`)
                else
                    toast.error("error in submitting form")
            }
            hideLoadingOverlay();
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    formId: CONFIG.formId,
                    userId: userId,
                }
            });
            hideLoadingOverlay()
        }
    };

    if (ipCountry === null) {
        toast.error("Something went wrong. Please try again later.")
        return null
    }

    return (
        <>

            <div
                className={
                    "flex flex-col max-w-7xl  mx-auto pb-20 items-center mb-80 p-3"
                }
            >
                <div className="flex flex-col mx-2">
                    <SectionHeader />
                </div>
                {
                    // existingSubmission?.dependentOf && <div>DEPENDENT</div>
                }
                {
                    existingSubmission?.paymentStatus === FORM_PAYMENT_STATUS.ERROR &&
                    <div className="flex flex-col w-full max-w-[760px] p-3 bg-red-800 text-white mt-12 select-none">
                        <p>Your last payment has failed. You can try your payment again by <strong onClick={() => {
                            navigate('/payment', {
                                replace: true
                            });
                        }} className="underline cursor-pointer">clicking here</strong></p>
                    </div>
                }
                {/* COUNTRY AND NATIONALITY */}
                <CountryDetails ref={countryDetailsRef} getRef={getRef} />

                {/* ATTENDANCE OPTIONS */}
                <AttendanceOptions ipCountry={ipCountry} ref={attendanceOptionsRef} getRef={getRef} />

                {/* PERSONAL DETAILS */}
                <PersonalDetails ref={personalDetailsRef} />

                {/* ADDRESS DETAILS */}
                <AddressDetails ref={addressDetailsRef} />

                {/* EMERGENCY DETAILS */}
                <EmergencyContactInformation ref={emergencyContactInformationRef} />

                {/* DOCUMENT UPLOAD */}
                <DocumentUpload ref={documentUploadRef} />

                {/* CO PARTICIPANTS */}
                <CoParticipants ref={coParticipantsDetailsRef} />

                {/* DECLARATION */}
                <Declarations onSave={saveForm} />

            </div>
        </>
    )
}

export default Form