
const SectionHeader = () => {

  return (
    <>
      <div className="serif-medium text-xl md:text-3xl text-center">
        Naga Pratishtha
      </div>
      <div className="mt-2 serif-medium md:text-xl text-base text-c73Orange">
        Isha Yoga Center, Bengaluru
      </div>
    </>
  );
};

export default SectionHeader;
