import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useGlobalState } from '../../store';
import LogoImage from "../../assets/images/Logo.png"

const pathBlackList = ['', '/', '/login', '/logout', '/oops', ''];
const Header = () => {
  const { firstName, lastName, userPhoto, userId } = useGlobalState();
  const location = useLocation();
  // console.log(p1SSOData);
  const onLogout = useCallback(() => {
    window.location.replace('/logout');
  }, []);
  if (pathBlackList.includes(location.pathname)) {
    return null;
  }

  if (userId === null) {
    return null;
  }

  return (
    <div style={{ backgroundColor: "#551207" }}>
      <div className="flex flex-row max-w-7xl mx-auto px-4 h-20 items-center ">
        <img
          alt='bhairavi-logo'
          onClick={() => {
            window.location.replace('/');
          }}
          src={
            LogoImage
          }
          className={'h-24 w-24 object-contain mr-auto cursor-pointer'}
        />
        <div className={'flex flex-col'}>
          <div className={'sans-medium mr-6 text-fd7'}>
            {firstName} {lastName}
          </div>
          <div
            className={'sans-normal text-sm underline cursor-pointer text-fd7'}
            onClick={onLogout}
          >
            Logout
          </div>
        </div>
        <img
          src={userPhoto || 'https://via.placeholder.com/150'}
          className="rounded-full w-12 h-12"
          alt="up"
        />
      </div>
    </div>
  );
};

export default Header;
