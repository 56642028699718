
import { ENVIRONMENT_TYPE } from 'config';
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './styles/tailwind.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
ENVIRONMENT_TYPE !== "PROD" && import('@hookstate/devtools')



if (import.meta.env.MODE === 'PROD') {
  console.log(
    '%c Did you do your practice today ?',
    'color: #D86E21; background: white; font-size: 30px'
  );
  const debugKey = localStorage.getItem('naga-enable-logs');
  if (debugKey !== 'show-me-the-truth') {
    console.log = () => { };
  }
}

Sentry.init({
  dsn: "https://fb729cda3695414d9b34edd413684154@o1329714.ingest.sentry.io/6591979",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
