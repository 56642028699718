import { differenceInYears } from "date-fns";
import { getGlobalState, useGlobalState } from "store";
import { date, mixed, number, object, string } from "yup"

const validationSchema = object().shape({
    firstName: string().required(),
    lastName: string().matches(/^[a-zA-Z ]+$/, "Enter valid last name"),
    email: string().email().required(),
    gender: string().required(),
    age: number().typeError("Age must be a number").min(12, "Age should be 12 or above").required(),
    dob: date()
        .typeError("Date of birth is a required field")
        .required("Date of birth is a required field")
        .test("age should be min 12 years", "Age should be 12 or above", function (value: any) {
            const currentDate: Date = new Date("2022-10-09T00:00:00+05:30"); // Calculate age as on program date;
            const age = Math.floor(differenceInYears(currentDate, value));
            return age >= 12
        })
    ,
    phone: string().required()
        .test("phone-check", "Enter valid phone number", function (value: string | undefined): boolean {
            if (value) {
                const { userCurrentPhoneCode } = getGlobalState();
                if (userCurrentPhoneCode === '+91')
                    return value.length === 10;
                else
                    return value.length >= 7 && value.length <= 14
            };
            return false;

        }),
    phoneCode: string().required(),
    wPhone: string().nullable()
        .test("phone-check", "Enter valid phone number", function (value: string | null | undefined): boolean {
            if (value) {
                const { userWPhoneCode } = getGlobalState();
                if (userWPhoneCode === '+91')
                    return value.length === 10;
                else
                    return value.length >= 7 && value.length <= 14
            };
            return true;
        }),
    wPhoneCode: string(),
    // isPregnant: string().nullable().when("gender", {
    //     is: (gender: string) => gender === GENDERS.FEMALE,
    //     then: string().required("This is required"),
    // }),
    guardianIdProofType: string().when("age", {
        is: (age: number) => age >= 12 && age < 18,
        then: string().required(),
    }),
    signedConsentForm: mixed().when('age', {
        is: (age: number) => age >= 12 && age < 18,
        then: mixed().required(),
    }),
    guardianPhoto: mixed().when('age', {
        is: (age: number) => age >= 12 && age < 18,
        then: mixed().required(),
    }),
    guardianAddressProof: mixed().when('age', {
        is: (age: number) => age >= 12 && age < 18,
        then: mixed().required(),
    }),
    guardianIdProofFile: mixed().when('age', {
        is: (age: number) => age >= 12 && age < 18,
        then: mixed().required(),
    }),
})

export default validationSchema