interface IProps {
  id: string;
  label: string | any;
  name: string;
  mode?: 'CHECKBOX' | 'RADIO';
  hookFormRegister?: object;
}
const Checkbox = (props: IProps) => {
  const { id, label, name, mode = 'CHECKBOX', hookFormRegister = {} } = props;
  if (mode === 'CHECKBOX')
    return (
      <div className={'flex flex-row  mt-4 items-center'} id={id}>
        <div className="">
          <input
            {...hookFormRegister}
            type="checkbox"
            name={name}
            className={'h-5 w-5 mr-4 '}
            id={`cbx${name}`}
          />
        </div>
        <label htmlFor={`cbx${name}`} className={'sans-normal-16 '}>{label}</label>
      </div>
    );
  else
    return <div className={'flex flex-col mt-4 space-y-2'} id={id}>
      <label htmlFor={`cbx${name}`} className={'sans-normal-16 '}>{label}</label>
      <div className=" space-x-2 items-center flex flex-row ">
        <label htmlFor={`cbx${name}-yes`} className={'items-center flex flex-row'}>
          <input
            {...hookFormRegister}
            type="radio"
            value={1}
            name={`${name}`}
            className={'h-5 w-5 mr-4 '}
            id={`cbx${name}-yes`}
          />
          Yes
        </label>
        <label htmlFor={`cbx${name}-no`} className={'items-center flex flex-row'}>
          <input
            {...hookFormRegister}
            type="radio"
            value={0}
            name={`${name}`}
            className={'h-5 w-5 mr-4 '}
            id={`cbx${name}-no`}
          />
          No
        </label>
      </div>
    </div>
};

export default Checkbox;
