import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "components/DropDown";
import PrimaryButton from "components/PrimaryButton";
import StepCardHolder from "components/StepCardHolder";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useGlobalState } from "store";
import { hideLoadingOverlay, showLoadingOverlay } from "utils";
import { axiosBridged } from "utils/network";
import validationSchema from "./validations";

const UpgradeCategoryCard = (props: { paymentData: any }) => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        // setError,
        // setFocus,
        getValues,
        control,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });
    const [batches, setBatches] = useState<IBatch[]>([])
    const { form, existingSubmission } = useGlobalState();
    const selectedBatchId = watch("batch");
    const batchToUpgrade = batches.find(b => b.id === selectedBatchId)
    const currentBatch = useMemo(() => batches.find(b => b.id === existingSubmission?.batch), [batches, existingSubmission?.batch])

    const [existingUpgrades, setExistingUpgrades] = useState<IBatchUpgrade[] | null>(null)

    useEffect(() => {
        const fetchBatches = async () => {
            try {
                const resp: IAPIResp = await axiosBridged.get("/batch/list", {
                    params: {
                        formId: form?.id
                    }
                })
                const batches: IBatch[] = resp.payload || []
                setBatches(batches);
                // if (ipCountry === "IN") {
                //     setAvailableBatches(batches.filter(a => a.isAvailable && !a.isReserved))
                // }
                // else {
                //     setAvailableBatches(batches.filter(a => a.isAvailableForOverseas && !a.isReserved))
                // }

            } catch (error) {
                console.log('error in fetchBatches-->', error);
                throw (error)
            }
        };

        const init = async () => {
            try {
                const upResp: IAPIResp = await axiosBridged.get("/batchUpgrade/list", {
                    params: {
                        subId: existingSubmission?.id
                    }
                })
                if (upResp.payload.length === 0) {
                    await fetchBatches()
                }
                setExistingUpgrades(upResp.payload)
            } catch (error) {
                console.log('error in init-->', error);
                throw (error)
            }
        };

        init()

    }, [])


    const getUpgradableBatches = () => {
        if (currentBatch) {
            console.log("EX ", existingSubmission)
            if (existingSubmission?.isOverseas) {
                return batches.filter(a =>
                    a.isAvailable && !a.isReserved &&
                    a.numberOfCoParticipants === currentBatch.numberOfCoParticipants &&
                    a.price > currentBatch.price
                ).sort((a, b) => a.price - b.price)
            }
            else {
                return batches.filter(a =>
                    a.isAvailable && !a.isReserved &&
                    a.numberOfCoParticipants === 1 &&
                    a.numberOfCoParticipants === currentBatch.numberOfCoParticipants &&
                    a.price > currentBatch.price
                ).sort((a, b) => a.price - b.price)
            }
        }
        return []
    }

    const getAmountToPay = () => {
        if (batchToUpgrade && currentBatch) {
            return `₹${batchToUpgrade.price - props.paymentData?.price}`
        }
        else {
            return "-"
        }
    }


    const onSubmit = async (data: any) => {
        try {
            showLoadingOverlay()
            const resp: IAPIResp = await axiosBridged.post("/batchUpgrade", {
                subId: existingSubmission?.id,
                from: currentBatch?.id,
                to: selectedBatchId,
            })
            if (resp.payload) {
                window.location.replace(`${window.location.origin}/upgradePayment/${resp.payload}`)
            }
            else {
                hideLoadingOverlay()
            }
        } catch (error) {
            hideLoadingOverlay()
            console.log("error in submit", error);
        }
    };

    if (existingUpgrades === null) {
        return null
    }
    if (existingUpgrades.length > 0) {
        return null
    }
    if (!currentBatch) {
        return null
    }
    if (currentBatch.numberOfCoParticipants > 1) {
        return null
    }

    return (
        <>
            <div
                className={
                    "flex flex-col max-w-7xl mx-auto  items-center pb-20 p-3"
                }
            >
                <StepCardHolder
                    heading={"Upgrade Category"}
                    subHeading={"Please provide category"}
                    id={"ao-card"}
                >
                    {existingSubmission?.batch && <Dropdown
                        label="Category*"
                        hookFormRegister={register('batch')}
                        ariaFor={'batch'}
                        id={'batch'}
                        name={'batch'}
                        // value={batch}
                        options={
                            [
                                {
                                    label: 'Select Category',
                                    value: ''
                                },
                                ...getUpgradableBatches().map(a => ({
                                    label: `${a.name} (₹${a.price})`,
                                    value: a.id
                                }))
                            ]
                        }
                        placeholderOption={'Select Category'}
                        errorMessage={errors?.batch?.message}
                    // disabled={relevantBatches.length === 0 || (existingSubmission?.dependentOf !== undefined && existingSubmission?.dependentOf !== null)}
                    />}
                    <div className="flex flex-row justify-between mt-10">
                        <div className="sans-normal text-base">Amount Paid</div>
                        <div className="serif-medium text-base">₹{props.paymentData?.price}</div>
                    </div>
                    <div className="flex flex-row justify-between mt-4">
                        <div className="sans-normal text-lg" >Balance Amount</div>
                        <div className="serif-medium text-lg" >{getAmountToPay()}</div>
                    </div>

                    <PrimaryButton
                        text={"Proceed to pay"}
                        onClick={handleSubmit(onSubmit)}
                        id={`pp-card-submit`}
                    />
                </StepCardHolder>
            </div>
        </>
    )
}

export default UpgradeCategoryCard