import { useEffect } from 'react';
import { CONFIG } from '../../config';

const Logout = () => {
  useEffect(() => {
    const waitForLoginButtonAndClick = () => {
      const el = document.getElementById('logout-button');
      if (el) {
        const formId = localStorage.getItem('formId');
        const ocoTargetSSOId = localStorage.getItem('ocoTargetSSOId');
        const redirectToShipping = localStorage.getItem('redirectToShipping');
        localStorage.clear();
        if (formId) {
          localStorage.setItem('formId', formId);
        }
        if (ocoTargetSSOId) {
          localStorage.setItem('ocoTargetSSOId', ocoTargetSSOId);
        }
        if (redirectToShipping) {
          localStorage.setItem('redirectToShipping', redirectToShipping);
        }
        el.click();
      } else {
        window.requestAnimationFrame(waitForLoginButtonAndClick);
      }
    };
    waitForLoginButtonAndClick();
  }, []);

  return (
    <>
      <div className={'mb-34'} />
      <form
        action={`${CONFIG.ssoUrl}/cm`}
        method="post"
        className={'flex flex-col flex-1'}
      >
        <input
          type="hidden"
          name="request_url"
          value={`${window.location.origin}/logout`}
        />
        <input
          type="hidden"
          name="logout_callback_url"
          value={`${CONFIG.baseApiUrl}/sso/callback`}
        />
        <input
          type="hidden"
          name="api_key"
          value="31d9c883155816d15f6f3a74dd79961b0577670ac"
        />
        <input
          type="hidden"
          name="hash_value"
          value={
            !window.location.href.includes('localhost')
              ? 'ebf48f3128f907bde9c501a2771ada4993a2aa805596d85addf250e08e736d42'
              : 'db490dffc263f56780b8c24e098768cad351fec2f094187a17eb7be634ef2738'
          }
        />
        <input
          type="hidden"
          name="enable_redirect"
          id="enable_redirect"
          value="1"
        />
        <button
          type="submit"
          id={'logout-button'}
          // className="w-64 mt-16 self-center bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg"
          className="hidden"
        >
          Log Out
        </button>
      </form>
    </>
  );
};

export default Logout;
