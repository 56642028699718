import { DevTool as FormTool } from '@hookform/devtools';

const HookFormDevTool = (props: { control: any }) => {
  const { control } = props;
  return (
    <>
      {import.meta.env.MODE !== 'PROD' && (
        <>
          <FormTool control={control} />

        </>
      )}
    </>
  );
};

export default HookFormDevTool;
