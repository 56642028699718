import axios from 'axios';

export let COUNTRY_DATA: [
  { countryName: string; countryCode: string; dialCode: string }
] | [] = [];

// export let LANGS: { code: string, name: string, nativeName: string }[] = [];

export const initCountryData = async () => {
  try {
    if (COUNTRY_DATA.length === 0) {
      const resp = await axios.get(
        'https://gprs-config.netlify.app/country-data.json'
      );
      COUNTRY_DATA = resp.data;
    }
  } catch (error) {
    console.log('error in initCountryData-->', error);
    throw error;
  }
};


// export const initLanguages = async () => {
//   try {
//     const resp = await axios.get(
//       'https://gprs-config.netlify.app/languages.json'
//     );
//     LANGS = resp.data;
//   } catch (error) {
//     console.log('error in initLanguage-->', error);
//     throw (error)
//   }
// };