import { ENVIRONMENT_TYPE } from "config";

interface IProps {
  heading: string;
  subHeading?: string;
  subHeadingHtml?: any;
  children: any;
  id: string;
  fillFunction?: any;
}
const StepCardHolder = (props: IProps) => {
  const { heading, subHeading, children, id, fillFunction, subHeadingHtml } = props;
  return (
    <div
      className="flex flex-col  w-full form-wrapper-card bg-white shadow-md rounded-md mt-16 md:p-10 px-4 py-6 md:mx-auto mx-3 relative"
      id={id}
    >
      <div className="serif-medium text-base md:text-xl">{heading}</div>{
        ((subHeadingHtml &&
          <div className="sans-normal text-sm text-777Gray" dangerouslySetInnerHTML={{ __html: subHeadingHtml || undefined }} />)
          ||
          <div className="sans-normal text-sm text-777Gray" >{subHeading}</div>
        )
      }
      {ENVIRONMENT_TYPE !== "PROD" && fillFunction &&
        <button className="my-1 border" onClick={fillFunction}>FILL</button>}
      <div className="w-full h-px mt-4 bg-gray-200" />
      {children}
    </div>
  );
};

export default StepCardHolder;
