import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { showLoadingOverlay } from 'utils';
import { CONFIG } from '../../config';
import { useGlobalState } from '../../store';

interface IProps {
  requestId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  pinCode: string;
  country: string;
  nationality: string;
  programBay: string;
  programParticipantsCount: number;
  programCost?: number;
  amount: number;
  upgradeParams?: IUpgradeProps | null;
  eReceiptParams: object | null;
  initializePayment: any;
}

const PaymentForm = (props: IProps) => {
  const {
    requestId,
    firstName,
    lastName,
    email,
    phone,
    address,
    city,
    state,
    pinCode,
    country,
    programBay,
    programParticipantsCount,
    programCost,
    amount,
    upgradeParams = {},
    eReceiptParams = null,
    initializePayment,
    nationality
  } = props;
  const { form } = useGlobalState()
  const isInitialized = useRef(false)

  useEffect(() => {
    const el = document.getElementById('payment-button');
    if (!form) {
      toast.error("Program not found")
      return
    }
    // if (el) {
    //   el.click();
    // }
    showLoadingOverlay()
    if (!isInitialized.current) {
      isInitialized.current = true
      initializePayment()
        .then(() => {
          if (el) {
            if (amount <= 0) {
              return;
            } else
              el.click();
          }
        })
        .catch(() => { });
    }


  }, []);


  if (!form) { return null }

  return (
    <>
      <form action={CONFIG.gpmsRequestURL} method="post">
        <input type="hidden" name="requestId" value={requestId} />
        <input type="hidden" name="programType" value={'NAGA'} />
        <input type="hidden" name="programCode" value={'NAGA'} />
        <input type="hidden" name="firstName" value={firstName} />
        <input type="hidden" name="lastName" value={lastName} />
        <input type="hidden" name="email" value={email} />
        <input type="hidden" name="mobilePhone" value={phone} />
        <input type="hidden" name="addressLine1" value={address} />
        <input type="hidden" name="addressLine2" value={''} />
        <input type="hidden" name="city" value={city} />
        <input type="hidden" name="state" value={state} />
        <input type="hidden" name="postCode" value={pinCode} />
        <input
          type="hidden"
          name="params"
          value={""}
        // value={`ereceipt.programbay=${programBay},ereceipt.programparticipantscount=${programParticipantsCount},ereceipt.programcost=${programCost}${getUpgradeParams()}${getExtraReceiptParams()}`}
        // value={`programBay=${programBay},program.name=${form.name},program.startdate=${new Date(form.programStartDate).toISOString()},program.enddate=${new Date(form.programEndDate).toISOString()},program.center=${form.center},program.region=${form.region}`}
        />

        <input type="hidden" name="country" value={country} />
        <input type="hidden" name="nationality" value={nationality} />
        <input type="hidden" name="amount" value={amount} />
        <input type="hidden" name="currency" value={'INR'} />
        <input
          type="hidden"
          name="callbackUrl"
          value={`${window.location.origin}/gpmsResponse`}
        />

        <button
          type="submit"
          id={'payment-button'}
          className="hidden w-32 mt-16 self-center bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg"
        >
          SUBMIT
        </button>
      </form>
    </>
  );
};

export default PaymentForm;
