import { getGlobalState } from "store";
import { object, string } from "yup"
const validationSchema = object().shape({
    countryOfResidence: string().required(),
    nationality: string().required(),
    pinCode: string().required().test("pincodeCheck", "Enter Valid Pincode", function (value: string | undefined): boolean {
        if (value) {
            const { countryOfResidence } = getGlobalState();
            if (countryOfResidence === 'IN')
                return (value && value.length === 6 && value.match(/^\d{6}$/) && true) || false;
            else return true;
        }
        return false;
    }),
    line1: string().min(15, "Address must be at least 15 characters").max(300, "Address must be below 300 characters").required("Address must be at least 15 characters"),
    city: string().required(),
    state: string().required(),
    shippingLine1: string().test("shippingLine1Check", "Address must between 15 to 300 characters", function (value: string | undefined): boolean {
        const { countryOfResidence, nationality } = getGlobalState();
        if (countryOfResidence === 'IN' && nationality === 'IN') {
            if (value && value.length >= 15 && value.length <= 300)
                return true
            return false;
        }
        return true;
    }),
    shippingCity: string().test("shippingCityCheck", "City is Required", function (value: string | undefined): boolean {
        const { countryOfResidence, nationality } = getGlobalState();
        if (countryOfResidence === 'IN' && nationality === 'IN') {
            if (value)
                return true
            return false;
        }
        return true;
    }),
    shippingState: string().test("shippingStateCheck", "State is Required", function (value: string | undefined): boolean {
        const { countryOfResidence, nationality } = getGlobalState();
        if (countryOfResidence === 'IN' && nationality === 'IN') {
            if (value)
                return true
            return false;
        }
        return true;
    }),
    shippingPinCode: string().test("shippingPinCodeCheck", "Enter Valid Pincode", function (value: string | undefined): boolean {
        const { countryOfResidence, nationality } = getGlobalState();
        if (countryOfResidence === 'IN' && nationality === 'IN') {
            if (value) {
                if (countryOfResidence === 'IN')
                    return (value.length === 6 && value.match(/^\d{6}$/) && true) || false;
                else return true;
            }
            return false;
        }
        return true;
    }),
    shippingCountry: string().test("shippingCountryCheck", "Country is Required", function (value: string | undefined): boolean {
        const { countryOfResidence, nationality } = getGlobalState();
        if (countryOfResidence === 'IN' && nationality === 'IN') {
            if (value)
                return true
            return false;
        }
        return true;
    }),
    sameAsBilling: string().required()
})

export default validationSchema