import useWatchUpdateGlobalState from "customHooks/useWatchFieldAndUpdateGlobalState";
import { useEffect } from "react";

interface IField {
    formFieldName: string;
    stateFieldName: string;
}
interface IProp {
    fileds: IField[];
    control: any
}
function WatchFormAndUpdateGlobalState({ fileds, control }: IProp) {
    for (let field of fileds) {
        useWatchUpdateGlobalState({
            formFieldName: field.formFieldName,
            stateFieldName: field.stateFieldName,
            control
        })
    }
    return null;
}
export default WatchFormAndUpdateGlobalState;