import FullScreenLoading from "components/FullScreenLoading";
import Header from "components/Header";
import GPMSResponse from "pages/GPMSResponse";
import Home from "pages/Home";
import Login from "pages/Login";
import ShippingAddress from "pages/shippingAddress";
import Logout from "pages/Logout";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { initCountryData } from "utils/countryData";
import TagManager from 'react-gtm-module'
import { useGlobalState } from "store";
import UpgradePayment from "pages/UpgradePayment";
import { doesQueryContainsAtLeastOneUTMParam } from "utils";

const AppRouter = () => {
    return <>
        <FullScreenLoading />
        <BrowserRouter>
            <Header />
            <AppRoutes />
        </BrowserRouter>
    </>
}
function AppRoutes() {
    const [isReady, setIsReady] = useState<boolean>(false);
    const pathsWithoutInit = useMemo(() => ["/login", "/gpmsResponse"], [])
    const { userId } = useGlobalState();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        // store utms
        //TODO: stop gap for coupon . remove it later
        const urlParams = new URLSearchParams(window.location.search);
        if (doesQueryContainsAtLeastOneUTMParam()) {
            localStorage.setItem("ip-utms", window.location.search)
        }
        const regMode = urlParams.get("regMode");
        if (regMode) {
            localStorage.setItem("regMode", "IN-SPL");
        }
        if (!userId && !pathsWithoutInit.includes(location.pathname)) {
            if (location.pathname.toLowerCase().includes('/update/shipping'))
                localStorage.setItem('redirectToShipping', "1");
            navigate("/login", { replace: true });
        }
    }, [userId])
    useEffect(() => {
        const TAG_ID = "GTM-5G2NJ8P";
        TagManager.initialize({
            gtmId: TAG_ID,
        });
    }, [])
    useEffect(() => {
        const init = async () => {
            try {
                await initCountryData();
                // await initLanguages();
                setIsReady(true);
            } catch (error) {
                console.log('error in init-->', error);
                toast.error('Something went wrong');
            }
        };

        init();
    }, []);
    const routesWithoutInit = <>
        <Route path="/login" element={<Login />} />
        <Route path="/gpmsResponse" element={<GPMSResponse />} />
    </>
    if (!userId) {
        return <Routes>
            {routesWithoutInit}
        </Routes>;

    }
    if (!isReady) {
        return null;
    }
    return (
        <Routes>
            {routesWithoutInit}
            <Route path="/logout" element={<Logout />} />
            <Route path="/update/shipping" element={<ShippingAddress />} />
            <Route path="/upgradePayment/:upgradeId" element={<UpgradePayment />} />
            <Route path="/*" element={<Home />} />
        </Routes>
    )
}
export default AppRouter