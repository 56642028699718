import { FORM_SUBMISSION_CANCELLED_MESSAGE, FORM_SUBMISSION_STATUS, REGISTRATION_COUPON_CHANNELS } from 'constants';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PaymentResponse from '../../components/PaymentResponse';
import { useGlobalState } from '../../store';
import { axiosBridged } from '../../utils/network';
import PassportReUpload from './components/PassportReUpload';
import UpgradeCategoryCard from './components/UpgradeCategoryCard';
import VisaReUpload from './components/VisaReUpload';
// import L2Questions from '../L2Questions';

const SubmissionTracker = () => {
  const { existingSubmission } = useGlobalState();
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState<{
    transactionId: string;
    status: string;
  } | null>(null);
  const [pendingPayments, setPendingPayments] = useState<{
    transactionId: string;
    status: string;
  }[] | null>([]);

  useEffect(() => {
    const getPaymentData = async () => {
      try {
        const resp: { payload: any } = await axiosBridged.get(
          `/submissions/payment?subId=${existingSubmission?.id}`
        );
        setPendingPayments(resp.payload.filter((a: any) =>
          a.status === "INPROGRESS" || a.status === "PENDING" || a.status === "INITIALIZED"));
        const successfulPayments = resp.payload.filter((a: any) => a.status === "COMPLETED")
        const sortedPayments = successfulPayments.sort((a: any, b: any) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        })

        setPaymentData({ ...sortedPayments[0] });
      } catch (error) {
        console.log('error in getPaymentData-->', error);
        toast.error('SOmething went wrong');
      }
    };
    if (existingSubmission && !existingSubmission.dependentOf) {
      getPaymentData();
    }
    window.scrollBy(0, 300);
  }, []);

  if (existingSubmission === null) {
    return <div>Loading ... </div>;
  }
  if (existingSubmission.status === FORM_SUBMISSION_STATUS.CANCELLED
    ||
    existingSubmission.status === FORM_SUBMISSION_STATUS.TRANSFERRED
  ) {
    navigate('/blk', {
      state: {
        message: FORM_SUBMISSION_CANCELLED_MESSAGE
      }
    });
    return null;
  }
  let coParticiapntMessage = "";
  if (existingSubmission.dependentOf) {
    coParticiapntMessage = existingSubmission.couponId &&
      (
        existingSubmission?.couponData?.channel === REGISTRATION_COUPON_CHANNELS.COMPLIMENTARY
        ||
        existingSubmission?.couponData?.channel === REGISTRATION_COUPON_CHANNELS.DONOR
        ||
        existingSubmission?.couponData?.channel === REGISTRATION_COUPON_CHANNELS.TRANSFER
      ) ?
      `
      <div class='sans-normal leading-7 text-base -mt-12 text-center max-w-3xl p-0'>
        Thank you for registering for Naga Pratishtha by Sadhguru. Your registration is successful. A detailed confirmation email will be sent to you shortly.
        <p class='-mt-6 -ml-4'>
          Pranam,
          Linga Bhairavi Team
        </p>
      </div>
  `
      :
      `
      <div class='sans-normal leading-7 text-base -mt-12 text-center max-w-3xl p-0'>
        You have successfully filled the registration form required to attend the Naga Pratishtha by Sadhguru at Isha Yoga Center, Bengaluru. <strong> {{primaryName}}</strong> will receive a payment link shortly to complete the registration.
        <p class='-mt-6 -ml-4'>
          Pranam,
          Linga Bhairavi Team
        </p>
      </div>
  `.replace("{{primaryName}}", `${existingSubmission?.primarySubmission?.profile?.firstName || ""} ${existingSubmission?.primarySubmission?.profile?.lastName} `)
  }


  return (
    <>
      {existingSubmission.dependentOf ?
        < div
          className={
            'flex flex-col items-center max-w-2xl md:mx-auto mt-20 mx-4'
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-20 w-20"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#CD9548"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
            Namaskaram
          </div>
          <div dangerouslySetInnerHTML={{
            __html: coParticiapntMessage
          }} className={'sans-normal text-base text-center mx-auto whitespace-pre-wrap'} />

        </div>
        :
        <>
          {(pendingPayments || []).length === 0 && <PaymentResponse
            status={paymentData?.status}
            transactionId={paymentData?.transactionId}
            {...paymentData}
          />}
          {
            (pendingPayments || []).map((a: any) => (
              <PaymentResponse
                status={a?.status}
                transactionId={a?.transactionId}
                {...a}
              />
            ))
          }
        </>

      }
      {
        existingSubmission.isOverseas && existingSubmission.isVisaVerified === false &&
        <div className={`text-center text-red-800 max-w-2xl mx-auto ${existingSubmission?.dependentOf ? "-mt-20" : "mt-10"} `}>
          <p>
            Please note: Your registration will be confirmed after you have uploaded an image of your valid Visa or OCI card or PIO card.
            Kindly use the link received in your email to upload your document.
            If you have already uploaded a valid document, you will receive confirmation after verification.
          </p>
          <p className='mt-5'>
            * Kindly ignore this message if you are an Indian passport holder.
          </p>
        </div>
      }
      {
        (existingSubmission.isOverseas && existingSubmission.isVisaVerified !== true) ?
          <VisaReUpload />
          : null
      }
      {
        (existingSubmission.isOverseas && existingSubmission.isPassportVerified !== true) ?
          <PassportReUpload />
          : null
      }
      <UpgradeCategoryCard paymentData={paymentData} />

    </>
  );
};

export default SubmissionTracker;
