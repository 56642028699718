import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
//@ts-ignore
// import { Service } from 'axios-middleware';
// import axiosRetry from 'axios-retry';
import { CONFIG } from 'config';
// import { getAuthToken } from 'store';
// import { hideLoadingOverlay } from 'utils';

export const axiosBridged = axios.create({
  baseURL: CONFIG.baseApiUrl,
  timeout: 10000,
  // withCredentials: true,
});

axiosBridged.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response?.data;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

// const service = new Service(axiosBridged);
// service.register({
//   onRequest(config: AxiosRequestConfig) {
//     // showLoadingOverlay();
//     return {
//       ...config,
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${getAuthToken()}`,
//       },
//     };
//   },

//   onRequestError(error: any) {
//     console.log('go to not internet page', error);
//     hideLoadingOverlay();
//   },

//   onResponse(response: AxiosResponse) {
//     // hideLoadingOverlay();
//     try {
//       // console.log(response.data)
//       const parsed = JSON.parse((response?.data ?? {}));
//       // console.log(parsed)
//       return parsed;
//     } catch (error) {
//       console.log(error, 'ERROR ON RESP');
//       return null
//     }
//   },

//   onResponseError(error: any) {
//     try {
//       hideLoadingOverlay();
//       if (error.message === 'Network Error') {
//         return;
//       }
//       if (error.response) {
//         if (error.response.status === 401 || error.response.status === '401') {
//           window.location.href = '/logout';
//         }
//       }
//       console.log("---> error parsing")
//       // toast.error(JSON.parse(error?.response?.data ?? {})?.message);
//       // throw new Error(JSON.parse(error?.response?.data ?? {})?.message);
//     } catch (error) {
//       console.log("ERROR IN PARSING RESPONSE", error)
//     }

//   },
// });

// axiosRetry(axiosBridged, { retries: 3 });
