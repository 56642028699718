import { axiosBridged } from './network';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import localforage from 'localforage';
import { captureException } from '@sentry/react';
export const getSSOData = async (userId: string) => {
  try {
    const resp: {
      payload: {
        countryOfResidence: string;
        email: string;
        firstName: string;
        gender: string;
        lastName: string;
        phone: any;
        whatsapp: any;
      };
    } = await axiosBridged.get(`/sso/basicProfile?userId=${userId}`);
    return resp.payload;
  } catch (error) {
    console.log('error in getSSOData-->', error);
    throw error;
  }
};


export const getDetailsOfPinCode = async (countryCode: any, pinCode: any) => {
  try {
    const resp = await axios.get(
      `https://cdi-gateway.isha.in/contactinfovalidation/api/countries/${countryCode}/pincodes/${pinCode}`
    );
    return resp.data;
  } catch (error) {
    console.log('error in getDetailsOfPinCode-->', error);
    throw error;
  }
};


/**
 *
 * @param email
 * @returns sso profile if exists, null otherwise
 * @summary
 * 1.checks if sso profile exists, response can be either null or
    "profileId": string,
    "email": string,
    "phone": null,
    "enabled": boolean
    "phoneVerified": any
    "emailVerified": boolean
 * 2. if it's not null, we'll get basicProfile information, and return it by merging both the responses(profileCheck,basicProfile)
      1. We can get the profile data
      2. It can be undefined too
 */

export const getSsoProfileOfUserByEmail = async (email: string) => {
  try {
    const profileResponse: IAPIResp = await axiosBridged.get(
      `/sso/profileCheck?email=${email}`
    );
    if (profileResponse.payload !== null) {
      const basicProfileResponse: IAPIResp = await axiosBridged.get(
        `/sso/basicProfile?userId=${profileResponse.payload.profileId}`
      );
      return {
        ...profileResponse.payload,
        ...basicProfileResponse.payload,
      };
    } else {
      return profileResponse.payload;
    }
  } catch (error) {
    console.log('error in getSsoProfileOfUserByEmail -->', error);
    throw error;
  }
};

/**
 *
 * @param email
 * @returns sso profile with (profileId) if exists, null otherwise
 */

export const getSsoProfileByEmail = async (email: string) => {
  try {
    const profileResponse: IAPIResp = await axiosBridged.get(
      `/sso/profileCheck?email=${email}`
    );
    return profileResponse.payload;
  } catch (error) {
    console.log('error in getSsoProfileByEmail -->', error);
    throw error;
  }
};

export const getSsoProfileBySsoId = async (profileId: string) => {
  try {
    const ssoProfileResponse: IAPIResp = await axiosBridged.get(
      `/sso/basicProfile?userId=${profileId}`
    );
    return ssoProfileResponse.payload;
  } catch (error) {
    console.log('error in getSsoProfileBySsoId-->', error);
    throw error;
  }
};

// DOM utils

/**
 *
 * @param id
 * @see https://encodingcompiler.com/community/391/javascript-scrollintoview-smooth-scroll-and-offset
 */

const SCROLL_TOP_OFFSET = 200;

export const scrollToNodeWithId = (id: string) => {
  try {
    const target = document.getElementById(id);
    if (target) {
      const headerOffset = SCROLL_TOP_OFFSET;
      const elementPosition = target.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',

      });
    }
  } catch (error) {
    console.log('error in scrollToNodeWithId-->', error);
  }
};

export const showLoadingOverlay = () => {
  const target = document.getElementById('full-screen-loader');
  if (target) {
    target.classList.remove('hidden');
  }
};

export const hideLoadingOverlay = () => {
  const target = document.getElementById('full-screen-loader');
  if (target) {
    target.classList.add('hidden');
  }
};


export const uploadFile = async (file: File, fileName: string, sentryTags: {
  userId: string,
  documentName: string
}) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    const resp: { payload: { url: string } } = await axiosBridged.post(
      '/submissions/uploadFile',
      formData, {
      timeout: 20000
    }
    );
    return decodeURIComponent(resp.payload.url);
  } catch (error: any) {
    console.log('error', error)
    captureException(error, {
      tags: sentryTags
    });
    if (error?.message.includes('timeout'))
      throw new Error(`${sentryTags.documentName} upload timed out.`);
    else
      throw new Error(`Cannot upload ${sentryTags.documentName}`);
  }
}

export const getIPFromGeoJS = async () => {
  try {
    const ipResp = await axios.get('https://get.geojs.io/v1/ip/country.json')
    return ipResp.data?.country
  } catch (error) {
    console.log('error in getIPFromGeoJS-->', error);
    return null
  }
};

const getIpCountryFromCloudFlare = async () => {
  try {
    const ipResp: any = await axios.get("https://cloudflare.com/cdn-cgi/trace", { timeout: 2000 })
    // console.log("CLOUD FLARE IP RESPONSE --> ", ipResp)
    const data = ipResp.data
    const arr = data.trim().split('\n').map((e: any) => e.split('='))
    const finalData = Object.fromEntries(arr)
    return finalData.loc
  } catch (error) {
    console.log('error in getIpCountryFromCloudFlare-->', error);
    return getIPFromGeoJS()
  }
};

export const getIPCountry = async () => {
  try {
    const ipResp: any = await axios.get("https://1.1.1.1/cdn-cgi/trace", { timeout: 2000 })
    const data = ipResp.data
    const arr = data.trim().split('\n').map((e: any) => e.split('='))
    const finalData = Object.fromEntries(arr)
    return finalData.loc
  } catch (error) {
    return getIpCountryFromCloudFlare()
  }
}

export const hasSSOProfile = async (email: string) => {
  try {
    // https://uat-profile.isha.in/services/pms/
    const resp: IAPIResp = await axiosBridged.get("/sso/profileCheck", {
      params: { email }
    })
    return resp?.payload ?? {}
  } catch (error) {
    captureException(error, {
      tags: {
        email
      }
    });
    throw error
  }
}

export const extractKeyFromS3Url = (url: string) => {
  try {
    const obj = new URL(url);
    obj.hash = "";
    obj.search = "";
    return obj.toString().replace("https://gprsstorage.s3.ap-south-1.amazonaws.com/", "");
  } catch (error) {
    console.log('error in extractKeyFromS3Url-->', error);
  }
};

export const GTMPush = async (userId: string, dataLayer: any) => {
  try {
    const vpAsString = await localforage.getItem(userId);
    let isExists = false;
    let vps: string[] = [];
    if (vpAsString) {
      try {
        vps = JSON.parse(vpAsString as string) as string[];
        isExists = vps.find(x => x === (dataLayer.virtual_path || dataLayer.event)) !== undefined;
      } catch {

      }
    }
    if (!isExists) {
      TagManager.dataLayer({
        dataLayer
      });
      vps.push(dataLayer.virtual_path || dataLayer.event);
      await localforage.setItem(userId, JSON.stringify(vps));
    }
  }
  catch {

  }
}

export const doesQueryContainsAtLeastOneUTMParam = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('utm_source') || urlParams.has('utm_medium') || urlParams.has('utm_campaign') || urlParams.has('utm_term') || urlParams.has('utm_content');
  } catch (error) {
    console.log('error in doesQueryContainsAtleastOneUTMParam-->', error);
  }
};