import { object, string, array } from "yup"
const validationSchema =
    object().shape({
        coParticipants: array().of(object().shape({
            firstName: string().required("First name is required"),
            lastName: string().required("Last name is required"),
            email: string().required("Email is required"),
        }))
    })



export default validationSchema