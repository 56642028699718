import { yupResolver } from '@hookform/resolvers/yup';
import Dropdown from 'components/DropDown';
import PrimaryButton from 'components/PrimaryButton';
import StepCardHolder from 'components/StepCardHolder';
import { FORM_COMPONENT_TYPE, REGISTRATION_COUPON_CHANNELS } from '../../../../constants';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useForm } from 'react-hook-form';
import { setGlobalState, useGlobalState } from 'store';
import { GTMPush, scrollToNodeWithId } from 'utils';
import { axiosBridged } from 'utils/network';
import validationSchema from './validations';
import HookFormDevTool from 'components/HookFormDevTool';

const AttendanceOptions = (props: { ipCountry: string, getRef: any }, ref: any) => {
    const { getRef } = props;
    const { userId, batch, preferredTranslationLanguage, dependentOf, couponId, couponData } = useGlobalState();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        // setError,
        // setFocus,
        getValues,
        control,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            batch: batch || undefined,
            preferredTranslationLanguage: preferredTranslationLanguage || undefined
        },
        mode: 'onChange',
    });
    const [isIndian, setIsIndian] = useState<boolean | null>(null)
    // const [availableBatches, setAvailableBatches] = useState<IBatch[] | null>(null)
    const [batches, setBatches] = useState<IBatch[]>([])
    const { form, existingSubmission } = useGlobalState();
    //Kannada, Tamil, Telugu, Hindi and Chinese
    const languageOptions = useRef(
        // LANGS.map((a) => ({
        //     label: `${a.name} (${a.nativeName})`,
        //     value: a.code,
        // }))
        [
            { label: "English", value: "en" },
            { label: "Tamil", value: "ta" },
            { label: "Telugu", value: "te" },
            { label: "Kannada", value: "kn" },
            { label: "Hindi", value: "hi" },

        ]
    );
    const currentBatch = watch("batch")
    useEffect(() => {
        if (currentBatch) {
            setGlobalState({
                batch: currentBatch
            });
        }
    }, [currentBatch])
    useImperativeHandle(ref, () => ({
        getData: async () => {
            try {
                const cers = await trigger();
                if (Object.keys(errors).length > 0 || cers === false) {
                    scrollToNodeWithId('ao-card');
                    throw new Error('error in AttendanceOptions')
                }
                const targetData = { ...getValues() };
                return targetData;
            } catch (error) {
                console.log('error in setting');
                throw error;
            }
        },
        setIndian: (cor: string, nat: string) => {
            if (cor && nat) {
                setIsIndian(cor === "IN" && nat === "IN")
            }
        }
    }));
    useEffect(() => {
        if (currentBatch) {
            const targetBatch = batches.find((b) => b.id === currentBatch)
            if (targetBatch && !existingSubmission?.dependentOf) {
                getRef(FORM_COMPONENT_TYPE.CO_PARTICIPANT_DETAILS)
                    .setNumberOfParticipants(targetBatch.numberOfCoParticipants - 1)
                if (targetBatch.numberOfCoParticipants - 1 > 0)
                    getRef(FORM_COMPONENT_TYPE.DOCUMENT_DETAILS)
                        .setNextFormId('cop-card')
                else getRef(FORM_COMPONENT_TYPE.DOCUMENT_DETAILS)
                    .setNextFormId('d-card');
            }
        }
    }, [currentBatch, batches])
    useEffect(() => {
        const fetchBatches = async () => {
            try {
                const resp: IAPIResp = await axiosBridged.get("/batch/list", {
                    params: {
                        formId: form?.id
                    }
                })
                const batches: IBatch[] = resp.payload || []
                setBatches(batches)
                // if (ipCountry === "IN") {
                //     setAvailableBatches(batches.filter(a => a.isAvailable && !a.isReserved))
                // }
                // else {
                //     setAvailableBatches(batches.filter(a => a.isAvailableForOverseas && !a.isReserved))
                // }

            } catch (error) {
                console.log('error in fetchBatches-->', error);
                throw (error)
            }
        };
        fetchBatches()

    }, [])


    const onSubmit = async (data: any) => {
        try {
            scrollToNodeWithId("pd-card");
            GTMPush(userId as string, {
                event: 'nagablr_vp_pushed',
                virtual_path: '/nagablr_vp_seating_cat_added'
            })
        } catch (error) {
            console.log("error in submit", error);
        }
    };

    const getRelevantBatches = () => {
        try {
            if (isIndian === null) {
                return []
            }
            else {
                if (existingSubmission?.dependentOf) {
                    return (batches.filter(a => !a.isReserved))
                }
                if (isIndian) {
                    if (localStorage.getItem("regMode") === "IN-SPL") {
                        return (batches
                            // .filter(a => a.isAvailable && !a.isReserved)
                            .filter(x => Boolean(couponId) || x.price > 500000)
                        )
                    }
                    else {
                        return (batches.filter(a => (Boolean(couponId) && couponData?.channel !== REGISTRATION_COUPON_CHANNELS.BACKOFFICE) || (a.isAvailable && !a.isReserved)).filter(x => Boolean(couponId) || x.price <= 500000))
                    }
                }
                else {
                    return (batches.filter(a => (Boolean(couponId) && couponData?.channel !== REGISTRATION_COUPON_CHANNELS.BACKOFFICE) || (a.isAvailableForOverseas && !a.isReserved)))
                }
            }
        } catch (error) {
            console.log('error in getRelaventBatches-->', error);
            return []
        }
    };

    if (batches.length === 0) {
        return <StepCardHolder
            heading={"Attendance Options"}
            subHeading={"Please provide Category and Language"}
            id={"ao-card"}
        >
            <div className='mt-2'>
                <ContentLoader
                    speed={1}
                    width={640}
                    height={84}
                    viewBox="0 0 v 84"
                    backgroundColor="#f6f6ef"
                    foregroundColor="#e8e8e3"
                    {...props}
                >
                    <rect x="9" y="4" rx="0" ry="0" width="640" height="22" />
                    <rect x="18" y="14" rx="0" ry="0" width="303" height="6" />
                    <rect x="11" y="33" rx="0" ry="0" width="108" height="13" />
                    <rect x="129" y="33" rx="0" ry="0" width="60" height="13" />
                    <rect x="196" y="33" rx="0" ry="0" width="60" height="13" />
                </ContentLoader>
            </div>
        </StepCardHolder>
    }
    const relevantBatches = getRelevantBatches().sort((a, b) => a.level - b.level);
    return (
        <>
            <StepCardHolder
                heading={"Registration Category"}
                subHeading={"Please provide category and language"}
                id={"ao-card"}
            >
                <div className="responsive-wrapper">
                    <Dropdown
                        label="Category*"
                        hookFormRegister={register('batch')}
                        ariaFor={'batch'}
                        id={'batch'}
                        name={'batch'}
                        value={currentBatch}
                        options={relevantBatches.map(a => {
                            const isFree = (
                                couponData?.channel === REGISTRATION_COUPON_CHANNELS.COMPLIMENTARY
                                ||
                                couponData?.channel === REGISTRATION_COUPON_CHANNELS.TRANSFER
                            );
                            return {
                                label: `${a.name} ${isFree ? "" : `(₹${a.price})`}`,
                                value: a.id
                            }
                        })}
                        placeholderOption={'Select Category'}
                        errorMessage={errors?.batch?.message}
                        disabled={relevantBatches.length === 0 || (existingSubmission?.dependentOf !== undefined && existingSubmission?.dependentOf !== null) || (couponId !== undefined && couponId !== null && couponData?.channel !== REGISTRATION_COUPON_CHANNELS.BACKOFFICE)}
                    />
                    <Dropdown
                        label="Preferred Translation Language*"
                        hookFormRegister={register('preferredTranslationLanguage')}
                        ariaFor={'preferredTranslationLanguage'}
                        id={'preferredTranslationLanguage'}
                        name={'preferredTranslationLanguage'}
                        options={languageOptions.current}
                        placeholderOption={'Translation Language '}
                        errorMessage={errors?.preferredTranslationLanguage?.message}
                    // disabled={true}
                    />
                </div>
                <PrimaryButton
                    text={"NEXT"}
                    onClick={handleSubmit(onSubmit)}
                    id={`pp-card-submit`}
                />
            </StepCardHolder>
            <HookFormDevTool control={control} />
        </>
    )
}

export default forwardRef(AttendanceOptions)