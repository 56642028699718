import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONFIG, ENVIRONMENT_TYPE } from '../../config';
import { setGlobalState } from '../../store';
import { getSSOData } from '../../utils';

const Login = () => {
  const [isResolved, setIsResolved] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const URL_PARAMS = new URLSearchParams(location.search);

  useEffect(() => {
    const processLogin = async () => {
      try {
        const waitForLoginButtonAndClick = () => {
          const el = document.getElementById('login-button');
          if (el) {
            el.click();
          } else {
            window.requestAnimationFrame(waitForLoginButtonAndClick);
          }
        };
        if (ENVIRONMENT_TYPE !== 'PROD') {
          const hashstring = JSON.stringify(
            {
              name: "DEAR TESTER, THIS IS A DEV LOG, WON'T APPEAR IN PRODUCTION. SO CHILL",
              requestUrl: `${window.location.origin}/login`,
              hashUsed: CONFIG.loginHash,
              callback_url: `${CONFIG.baseApiUrl}/sso/callback`,
              lang: URL_PARAMS.get('lang'),
            },
            null,
            2
          );
          // console.log('hash->' + hashstring)
          // alert(hashstring);
        }
        const ssoId = URL_PARAMS.get('uid');
        const authToken = URL_PARAMS.get('token');
        const rawUserPhoto = URL_PARAMS.get('photo') || '';
        const hasConsent = URL_PARAMS.get('hasConsent');

        localStorage.setItem('userId', ssoId === null ? '' : ssoId);
        if (!ssoId) {
          setIsResolved(true);
          waitForLoginButtonAndClick();
        } else {
          if (hasConsent !== '1') {
            toast.error('needs consent');
            navigate('/login', { replace: true });
            return;
          }
          const allSSOData: any = await getSSOData(ssoId);
          setGlobalState({
            userId: ssoId,
            authToken,
            userEmail: URL_PARAMS.get('email'),
            userPhoto: unescape(decodeURIComponent(atob(rawUserPhoto))),
            ...allSSOData,
            userPhoneCode: allSSOData?.code,
            userPhone: allSSOData?.phone,
            userWPhoneCode: allSSOData?.wcode,
            userWPhone: allSSOData?.whatsAppNumber,
            userGender: allSSOData?.gender,
          });
          const redirectoShippingaddressForm = localStorage.getItem('redirectToShipping') === "1"
          if (redirectoShippingaddressForm) {
            localStorage.removeItem('redirectToShipping');
            window.location.replace('/update/shipping');
            // navigate(``, { replace: true, });
            return;
          }
          navigate('/', { replace: true });
        }
      } catch (error) {
        console.log('error in processLogin-->', error);
        throw error;
      }
    };
    processLogin();
  }, [URL_PARAMS, location.search]);

  //   if (userId !== null) {
  //     return <Redirect to="/" />
  //   }

  return (
    <>
      {isResolved ? (
        <form
          action={CONFIG.ssoUrl}
          method="post"
          id="login_form_watch_live"
          className={'flex flex-col flex-1 pt-64'}
        >
          <input
            type="hidden"
            name="request_url"
            value={`${window.location.origin}/login`}
          />
          <input
            type="hidden"
            name="callback_url"
            value={`${CONFIG.baseApiUrl}/sso/callback`}
          />
          <input
            type="hidden"
            name="api_key"
            value="31d9c883155816d15f6f3a74dd79961b0577670ac"
          />
          <input type="hidden" name="hash_value" value={CONFIG.loginHash} />
          <input type="hidden" name="action" value="0" />
          <input type="hidden" name="legal_entity" value="SYT" />
          <input type="hidden" name="force_consent" value="1" />
          <input
            type="hidden"
            name="lang"
            id="sso_lang"
            t-att-value={URL_PARAMS.get('lang') || 'en'}
            value={URL_PARAMS.get('lang') || 'en'}
          />

          <button
            type="submit"
            id={'login-button'}
            className="hidden w-32 mt-16 self-center bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg"
          >
            LOGIN
          </button>
        </form>
      ) : (
        <h2>Processing...</h2>
      )}
    </>
  );
};

export default Login;
