import { useEffect, useState } from 'react';
import { setGlobalState, useGlobalState } from 'store';
import { axiosBridged } from 'utils/network';
//@ts-ignore
import { yupResolver } from '@hookform/resolvers/yup';
import UploadButton from 'components/UploadButton';
import { useForm } from 'react-hook-form';
import { extractKeyFromS3Url, hideLoadingOverlay, showLoadingOverlay, uploadFile } from 'utils';
import validationSchema from './validations';
import PrimaryButton from 'components/PrimaryButton';
import toast from 'react-hot-toast';
import StepCardHolder from 'components/StepCardHolder';
const PassportReUpload = () => {

    const { existingSubmission, userId } = useGlobalState();

    const [existingPassport, setExistingPassport] = useState<string | null>(null)

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        control,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'all',
    });

    useEffect(() => {

        const { passport } = existingSubmission?.data ?? {};
        setExistingPassport(passport);
    }, [])

    const onSubmit = async (data: any) => {
        try {
            if (!existingSubmission) {
                toast.error("Something went wrong")
                return
            }
            showLoadingOverlay()
            const newPassportFile = await uploadFile(data.passportFile, `${userId}-passportFile`, {
                userId: userId as string, documentName: "passport"
            });
            await axiosBridged.put("/submissions/passport", {
                passport: newPassportFile,
                subId: existingSubmission.id
            })
            setGlobalState({
                existingSubmission: {
                    ...existingSubmission,
                    data: { ...existingSubmission.data, passport: newPassportFile }
                }
            })
            setExistingPassport(newPassportFile)
            hideLoadingOverlay()
        } catch (error: any) {
            hideLoadingOverlay()
            toast.error(error.message)
        }
    };


    if (existingSubmission) {
        if (existingSubmission.isOverseas) {
            return (
                <StepCardHolder
                    heading={existingPassport ? 'Passport Reupload' : 'Passport Upload'}
                    subHeading={''}
                    id={'repassport-card'}
                >
                    <div
                        className={
                            'flex flex-col items-center max-w-xl md:mx-auto mx-4'
                        }
                    >
                        <div className='flex flex-row items-center'>
                            {existingPassport &&
                                <div className="flex flex-col mr-10">
                                    <>
                                       <img
                                            className={`w-16 h-16 object-contain ${existingPassport && 'cursor-pointer'}`}
                                            src={existingPassport}
                                            alt={"passport"}
                                            onClick={() => existingPassport && window.open(existingPassport)}

                                        />
                                        <div className="sans-normal mt-2 text-center text-sm text-777Gray">
                                            PREVIOUS UPLOAD
                                        </div>
                                    </>
                                </div>
                            }
                            <UploadButton
                                text={'Passport File (Max 2MB)'}
                                name={'passportFile'}
                                control={control}
                                label={'passport file'}
                                errorMessage={errors?.passportFile?.message}
                            />
                        </div>

                        <PrimaryButton
                            text={existingPassport ? 'UPDATE PASSPORT' : 'UPLOAD PASSPORT'}
                            onClick={handleSubmit(onSubmit)}
                            id={`pp-card-submit`}
                        />
                    </div>
                </StepCardHolder>
            )
        }
        else {
            return null
        }
    }
    else {
        return null
    }
}


export default PassportReUpload