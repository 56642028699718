import { createState, Downgraded, useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
interface IShippingAddress {
  line1: string | null;
  city: string | null;
  state: string | null;
  pinCode: string | null;
  country: string | null;
}
export interface IGlobalStateProps {
  authToken: string | null;
  userId: string | null;
  userPhoto: string | null;
  //Personal Details
  firstName: string | null;
  lastName: string | null;
  userEmail: string | null;
  gender: string | null;
  userCurrentPhoneCode: string | null;
  userCurrentPhone: string | null;
  userWPhoneCode: string | null;
  userWPhone: string | null;
  age: number | null;
  dob: string | null;
  sameAsPhone: boolean | null;
  signedConsentForm?: string | null;
  guardianPhoto?: string | null;
  guardianAddressProof?: string | null;
  guardianIdProofType?: string | null;
  guardianIdProofFile?: string | null;
  couponId?: string | null;
  couponData?: any | null;
  //Country Details
  countryOfResidence: string | null;
  nationality: string | null;
  //Batch Details
  batch: string | null;
  preferredTranslationLanguage: string | null;
  // Address Details
  pinCode: string | null;
  line1: string | null;
  city: string | null;
  state: string | null;
  shipping: IShippingAddress | null;
  // emergency contact details
  emergencyContactName: string | null;
  emergencyContactPhoneCode: string | null;
  emergencyContactPhone: string | null;
  emergencyContactRelation: string | null;
  // document details
  photo: string | null;
  addressProof: string | null;
  idProof: string | null;
  passport: string | null;
  visa: string | null;
  idProofType: string | null;
  CovidVaccinationStatus: string | null;
  // form details
  form: IForm | null;
  dependentOf: string | null;
  existingSubmission: null | ISubmission;
  batches: IBatch[] | null;
  isResubmission: boolean | null;
  // coParticpiant
  coParticipants: any[] | null;
}

const INITIAL_STATE: IGlobalStateProps = {
  authToken: null,
  userId: null,
  userPhoto: null,
  firstName: null,
  lastName: null,
  userEmail: null,
  gender: null,
  userCurrentPhoneCode: null,
  userCurrentPhone: null,
  userWPhoneCode: null,
  userWPhone: null,
  sameAsPhone: null,
  age: null,
  dob: null,
  signedConsentForm: null,
  guardianPhoto: null,
  shipping:null,
  guardianAddressProof: null,
  guardianIdProofType: null,
  guardianIdProofFile: null,
  countryOfResidence: null,
  nationality: null,
  batch: null,
  preferredTranslationLanguage: null,
  pinCode: null,
  line1: null,
  city: null,
  state: null,
  emergencyContactName: null,
  emergencyContactPhoneCode: null,
  emergencyContactPhone: null,
  emergencyContactRelation: null,
  photo: null,
  addressProof: null,
  idProof: null,
  passport: null,
  visa: null,
  idProofType: null,
  CovidVaccinationStatus: null,
  form: null,
  dependentOf: null,
  existingSubmission: null,
  batches: null,
  isResubmission: null,
  coParticipants: null,
};

const globalState = createState<IGlobalStateProps>({ ...INITIAL_STATE });

export const resetGlobalState = () => {
  try {
    globalState.set({ ...INITIAL_STATE });
  } catch (error) {
    console.log('error in resetGlobalState-->', error);
  }
};



export const getAuthToken = () => {
  return globalState.get().authToken;
};


export function getGlobalState(): IGlobalStateProps {
  return globalState.get();
}


export function useGlobalState() {
  // This function exposes the state directly.
  // i.e. the state is accessible directly outside of this module.
  // The state for settings in SettingsState.ts wraps the state by an interface.
  // Both options are valid and you need to use one or another,
  // depending on your circumstances. Apply your engineering judgement
  // to choose the best option. If unsure, exposing the state directly
  // like it is done below is a safe bet.
  // try {
  globalState.attach(Persistence('naga'));
  // } catch (error) {
  //   console.log(error);
  // }

  return useState(globalState).attach(Downgraded).get();
}

export const setGlobalState = (data: Partial<IGlobalStateProps>) => {
  try {
    globalState.set((prev: any) => ({ ...prev, ...data }));
  } catch (error) {
    console.log(error);
  }
};
