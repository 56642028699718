interface buttonInterface
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

const PrimaryButton = (props: buttonInterface) => {
  const { text, onClick, type } = props;

  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className="sans-normal text-base text-white w-full h-14 self-center mt-16 bg-237Blue  focus:ring-indigo-500 focus:ring-offset-indigo-200  transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
      >
        {text}
      </button>
    </>
  );
};

export default PrimaryButton;
