import { REGISTRATION_COUPON_CHANNELS } from 'constants';
import { useEffect } from 'react';
import { useGlobalState } from 'store';
import { GTMPush } from 'utils';
import errorIcon from '../../assets/images/failed.svg';
import pendingIcon from '../../assets/images/pending.svg';
import successIcon from '../../assets/images/success.svg';

interface IProps {
  status?: string;
  transactionId?: string;
  price?: number;
  rawResp?: any;
}

const PaymentResponse = (props: IProps) => {
  const { userId, existingSubmission } = useGlobalState();
  const { status = 'INPROGRESS', transactionId, price, rawResp } = props;
  useEffect(() => {
    try {
      if (status === 'COMPLETED') {
        GTMPush(userId as string, {
          event: 'nagablr_vp_pushed',
          virtual_path: '/nagablr_vp_success'
        });
        GTMPush(userId as string, {
          event: 'purchase_pixel_nagablr',
          purchase_amount: price,
          purchase_currency: rawResp?.currency
        });
      }
    } catch { }
  }, [status, userId, price, rawResp])
  // const [form, setForm] = useState<IForm | null>(null);
  // useEffect(() => {
  //   const fetchTargetForm = async () => {
  //     try {
  //       const formId = localStorage.getItem('formId');
  //       if (formId) {
  //         const targetFormResp: IAPIResp = await axiosBridged.get(
  //           '/user/form',
  //           {
  //             params: { id: formId },
  //           }
  //         );
  //         setForm(targetFormResp.payload);
  //       }
  //     } catch (error) {
  //       console.log('error in fetchTargetForm-->', error);
  //       throw error;
  //     }
  //   };
  //   fetchTargetForm();
  // }, []);
  if (status === 'COMPLETED' || status === 'COMPLIMENTARY_COMPLETED') {
    return (
      <div
        className={
          'flex flex-col items-center max-w-2xl md:mx-auto md:mt-16 mt-10 mx-4'
        }
      >
        <img src={successIcon} alt={'success'} className={'w-20 h-20'} />
        {
          existingSubmission?.couponId && (
            existingSubmission?.couponData?.channel === REGISTRATION_COUPON_CHANNELS.COMPLIMENTARY
            ||
            existingSubmission?.couponData?.channel === REGISTRATION_COUPON_CHANNELS.DONOR
            ||
            existingSubmission?.couponData?.channel === REGISTRATION_COUPON_CHANNELS.TRANSFER
          )
            ?
            <>
              <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
                Registration Successful
              </div>
              <div className={'sans-normal text-base mt-6 text-center'}>
                <span className='text-lg'>Namaskaram</span>
                <br />
                <br />
                Thank you for registering for Naga Pratishtha by Sadhguru. Your registration is successful. A detailed confirmation email will be sent to you shortly.
                <br />
                <br />
                Pranam,
                <br />
                Linga Bhairavi Team
              </div>
            </>
            :
            <>
              <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
                Transaction Successful
              </div>
              <div className={'sans-normal text-base mt-6 text-center'}>
                Namaskaram, Thank you for registering for Naga Pratishtha.
                Your payment with payment reference number <b>{transactionId}</b> of{' '}

                <b>Rs. {price}</b> towards{' '}
                <b>
                  {rawResp?.params?.['programBay']
                    ?.toLowerCase()
                    ?.replace('_', ' ')}{' '}
                </b>
                was successful. A detailed confirmation email will be sent to you
                shortly.
                <br />
                <br />
                Pranam,
                <br /> Linga Bhairavi Team
              </div>
            </>
        }
      </div >
    );
  }
  if (status === 'INPROGRESS' || status === 'PENDING' || status === 'INITIALIZED') {
    return (
      <div
        className={
          'flex flex-col items-center max-w-xl md:mx-auto md:mt-16 mt-10 mx-4'
        }
      >
        <img src={pendingIcon} alt={'pending'} className={'w-20 h-20'} />
        <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
          Transaction Pending
        </div>
        <div className={'sans-normal text-base mt-6 text-center'}>
          Namaskaram, If you have attempted a payment{transactionId && <> with transaction id{' '}
            <strong>{transactionId}</strong></>}, please allow a minimum of 40 minutes to receive an update regarding your payment status on your registered email ID.
          <br />
          <br />
          In case you have not attempted or cancelled your payment, please allow a minimum of 40 minutes before you re-attempt the payment.
          <br />
          <br />
          For further clarifications, please get in touch with us on <a className='text-blue-900 underline cursor-pointer' href={'tel:+918300053111'}>8300053111</a> or email us at <a
            href={'mailto:info.iycbangalore@lingabhairavi.org'}
            className={'text-blue-900 underline cursor-pointer'}
          >info.iycbangalore@lingabhairavi.org</a>
          <br />
          <br />
          Pranam,
          <br /> Linga Bhairavi Team
        </div>
        <div className='px-12 bg-[#551207] text-white sans-normal font-medium py-2 mt-8 rounded cursor-pointer hover:shadow-xl' onClick={() => window.location.replace('/')}>
          Refresh
        </div>
      </div >
    );
  }
  if (status === 'ERROR') {
    return (
      <div
        className={
          'flex flex-col items-center max-w-xl md:mx-auto md:mt-32 mt-20 mx-4'
        }
      >
        <img src={errorIcon} alt={'error'} className={'w-20 h-20'} />
        <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
          Transaction Failed
        </div>
        <div className={'sans-normal text-base mt-6 text-center'}>
          Namaskaram, Your payment status with transaction id{' '}
          <strong>{transactionId}</strong> has failed. In case the amount is
          debited from your account for this transaction, it would be auto
          refunded.
          <br />
          <br />
          If you have not successfully registered yet, you could retry now.
          <br />
          <br />
          For any questions, please get in touch with us at
          <a
            href={'mailto:info@mahashivarathri.org'}
            className={'text-blue-900 underline cursor-pointer'}
          >
            {' '}
            info.iycbangalore@lingabhairavi.org
          </a>
          <br />
          <br />
          Pranam,
          <br /> Linga Bhairavi Team
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        'flex flex-col items-center max-w-xl md:mx-auto md:mt-32 mt-20 mx-4'
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-20 w-20"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#CD9548"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
      <div className={'serif-medium text-2xl md:text-3xl mt-10 text-center'}>
        Something Went Wrong
      </div>
      <div className={'sans-normal text-base mt-6 text-center'}>
        Namaskaram, We are unable to process your registration.
        <br />
        <br />
        If you have not successfully registered yet, you could retry now.
        <br />
        <br />
        For any questions, please get in touch with us at
        <a
          href={'mailto:info@mahashivarathri.org'}
          className={'text-blue-900 underline cursor-pointer'}
        >
          {' '}
          info.iycbangalore@lingabhairavi.org
        </a>
        <br />
        <br />
        Pranam,
        <br /> Linga Bhairavi Team
      </div>
    </div>
  );
};

export default PaymentResponse;
