export const STANDARD_YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const ID_PROOF_TYPES = [
  {
    label: "Aadhaar",
    value: "AADHAAR",
  },
  {
    label: "Passport",
    value: "PASSPORT",
  },
  {
    label: "VoterID",
    value: "VOTER_ID",
  },
  {
    label: "DrivingLicense",
    value: "DRIVING_LICENSE",
  },
  {
    label: "PANCard",
    value: "PAN_CARD",
  },
  {
    label: "RationCard",
    value: "RATION_CARD",
  },
];
export const COVID_VACCINATION_TYPES = [
  {
    label: "Fully Vaccinated",
    value: "Fully Vaccinated",
  },
  {
    label: "Paritally Vaccinated",
    value: "Paritally Vaccinated",
  },
  {
    label: "Not Vaccinated",
    value: "Not Vaccinated",
  },

];



export enum GENDERS {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

export const DUMMY_BATCHES = [
  {
    label: "Batch 1",
    value: "b1"
  },
  {
    label: "Batch 2",
    value: "b2"
  },
  {
    label: "Batch 3",
    value: "b3"
  },
  {
    label: "Batch 4",
    value: "b4"
  },
]

export const GENDERS_OPTIONS = Object.keys(GENDERS).map((a) => ({
  label: a,
  value: a,
}));

export const RELATIONSHIPS = [
  {
    label: "Spouse",
    value: "spouse",
  },
  {
    label: "Father",
    value: "father",
  },
  {
    label: "Mother",
    value: "mother",
  },
  {
    label: "Son",
    value: "son",
  },
  {
    label: "Daughter",
    value: "daughter",
  },
  {
    label: "Brother",
    value: "brother",
  },
  {
    label: "Sister",
    value: "sister",
  },
];

export enum FORM_COMPONENT_TYPE {
  COUNTRY_DETAILS = 'COUNTRY_DETAILS',
  ATTENDANCE_OPTIONS = 'ATTENDANCE_OPTIONS',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  ADDRESS_DETAILS = 'ADDRESS_DETAILS',
  EMERGENCY_DETAILS = 'EMERGENCY_DETAILS',
  CO_PARTICIPANT_DETAILS = 'CO_PARTICIPANT_DETAILS',
  DOCUMENT_DETAILS = 'DOCUMENT_DETAILS',
}

export enum FORM_STEP {
  FORM = 'FORM',
  PAYMENT = 'PAYMENT',
  RESUBMISSION = 'RESUBMISSION',
  FINAL = 'FINAL',
}

export enum MINOR_STATUS {
  NONE = 'NONE',
  BLOCKED = 'BLOCKED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum IMS_STATUS {
  NONE = "NONE",
  BLOCKED = "BLOCKED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum FORM_PAYMENT_STATUS {
  NONE = 'NONE',
  INITIALIZED = 'INITIALIZED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export enum HARDSHIP_STATUS {
  NONE = 'NONE',
  BLOCKED = 'BLOCKED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum DEPENDANT_STATUS {
  NONE = 'NONE',
  BLOCKED = 'BLOCKED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum FORM_SUBMISSION_STATUS {
  COMPLETED = 'COMPLETED',
  INITIALIZED = 'INITIALIZED',
  CANCELLED = 'CANCELLED',
  UPGRADE_COMPLETED = 'UPGRADE_COMPLETED',
  TRANSFERRED = 'TRANSFERRED'
}

export const MULTI_BLOCK_MESSAGE = `

Thank you for expressing your interest in Naga Pratishtha.
Your application is under review.
A volunteer will get in touch with you if additional information is needed.
Else, we will let you know as soon as the review is complete.

Pranam,
Linga Bhairavi Team
`

export const CO_PARTICIPANT_MESSAGE = `
<div className={'sans-normal text-base -mt-12  text-center'}>
Your Naga Pratishtha registration for the BATCH category has been initiated by another guest. They have been notified about the next steps.
If you would like to know the status of the registration, or have any
other questions, please reach out to <a
  href={'mailto:info.iycbangalore@lingabhairavi.org'}
  className={'text-blue-900 underline cursor-pointer'}
>
  info.iycbangalore@lingabhairavi.org.
</a>
Pranam,
Linga Bhairavi Team
</div>
`

export const PRIMARY_PARTICIPANT_MESSAGE = `
<div class='sans-normal text-base -mt-4 max-w-7xl  text-center'>
Thank you for registering for Naga Pratishtha by Sadhguru at Isha Yoga Center, Bengaluru. A registration form has been sent to <strong>{{coParticipants}}</strong> and we will get back to you, once the form has been completed.

Pranam,
Linga Bhairavi Team
</div>
`;

export const COUPLE_PAYMENT_BLOCK = `
<div class='sans-normal text-base -mt-4 max-w-7xl  text-center'>
Thank you for completing the registration form. The payment will be enabled in 24 hours time.

Pranam,
Linga Bhairavi Team
</div>
`;

export const FORM_SUBMISSION_CANCELLED_MESSAGE = `
<div class='sans-normal leading-7 text-base text-center max-w-3xl p-0'>
        Your registration for Naga Pratishtha by Sadhguru at Isha Yoga Center, Bengaluru is <strong>cancelled</strong>.
        For further clarifications, please get in touch with us on <a class="font-bold underline" href="tel:+918300053111">8300053111</a> or email us at <a class="font-bold underline" href="mailto:info.iycbangalore@lingabhairavi.org">info.iycbangalore@lingabhairavi.org</a>
        <p class='-mt-8 -ml-8'>
          Pranam,
          Linga Bhairavi Team
        </p>
</div>`
export const FORM_SUBMISSION_CLOSED_MESSAGE = `
<div class='sans-normal leading-7 text-base text-center max-w-3xl p-0'>
        Registrations are closed! 
        <p class='-mt-8 -ml-8'>
          Pranam,
          Linga Bhairavi Team
        </p>
</div>`
export enum BATCH_UPGRADE_STATUS {
  INITIALIZED = 'INITIALIZED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}


export enum REGISTRATION_COUPON_CHANNELS {
  COMPLIMENTARY = 'COMPLIMENTARY',
  HARDSHIP = 'HARDSHIP',
  DONOR = 'DONOR',
  TRANSFER = 'TRANSFER',
  BACKOFFICE = 'BACKOFFICE'
}