import PaymentForm from 'components/PaymentForm';
import { BATCH_UPGRADE_STATUS } from 'constants';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useGlobalState } from 'store';
import { axiosBridged } from 'utils/network';

const UpgradePayment = () => {
    const params = useParams();
    const upgradeId = params.upgradeId;
    const [upgradeData, setUpgradeData] = useState<IBatchUpgrade | null>(null);
    const { existingSubmission } = useGlobalState()
    // const [paymentInfo, setPaymentInfo] = useState<IPayment | null>(null);

    // const [previousSubmission, setPreviousSubmission] =
    //     useState<ISubmission | null>(null);

    useEffect(() => {

        const fetchUpgrade = async () => {
            try {
                const resp: IAPIResp = await axiosBridged.get('/batchUpgrade', {
                    params: { upgradeId },
                });
                console.log("UPGRADE DATA ", resp.payload);
                setUpgradeData(resp.payload);
            } catch (error) {
                console.log('error in fetchUpgrade-->', error);
                throw (error)
            }
        };
        upgradeId && fetchUpgrade();

    }, [])

    const initializePayment = async () => {
        try {
            await axiosBridged.post('/batchUpgrade/initPayment', {
                upgradeId,
            });
        } catch (error) {
            console.log('error in initializePayment-->', error);
            throw error;
        }
    };




    if (!upgradeId) {
        return <div className='mx-auto mt-40 text-center sans-normal text-xl'>Processing....</div>;
    }

    if (
        upgradeData === null

    ) {
        return <div className='mx-auto mt-40 text-center sans-normal text-xl'>UPGRADE PROCESSING...</div>;
    }
    if (upgradeData.status === BATCH_UPGRADE_STATUS.PENDING) {
        return <div className='mx-auto mt-40 text-center sans-normal text-xl'>UPGRADE PROCESSING...</div>;
    }


    //upgrade completed
    if (upgradeData.status === BATCH_UPGRADE_STATUS.COMPLETED) {
        return (
            <div className='mx-auto mt-40 text-center sans-normal text-xl'>
                Upgrade is completed. Please check your email for updated seating pass.
            </div>
        );
    }

    if (existingSubmission && (upgradeData.status === BATCH_UPGRADE_STATUS.INITIALIZED || upgradeData.status === BATCH_UPGRADE_STATUS.ERROR)) {
        return (
            <>
                <PaymentForm
                    requestId={upgradeData.id}
                    firstName={existingSubmission?.data.firstName}
                    lastName={existingSubmission?.data.lastName}
                    email={existingSubmission?.data.email}
                    amount={upgradeData.diffAmount}
                    address={existingSubmission?.data.line1}
                    city={existingSubmission?.data.city}
                    state={existingSubmission?.data.state}
                    country={existingSubmission?.data.countryOfResidence}
                    nationality={existingSubmission?.data?.nationality}
                    phone={existingSubmission?.data.phone}
                    pinCode={existingSubmission?.data.pinCode}
                    programBay={upgradeData.to}
                    // programCost={upgradeData.diffAmount}
                    programParticipantsCount={1}
                    eReceiptParams={{}}
                    initializePayment={initializePayment}
                />
            </>
        )
    }

    return <>Processing...</>
}

export default UpgradePayment