import { getGlobalState } from "store";
import { boolean, object, string } from "yup"
const validationSchema = object().shape({
    country: string().required(),
    sameAsBilling: boolean().required(),
    pinCode: string().required().test("pincodeCheck", "Enter Valid Pincode", function (value: string | undefined, context): boolean {
        if (value) {
            if (context.parent?.country === 'IN')
                return (value && value.length === 6 && value.match(/^\d{6}$/) && true) || false;
            else
                return true;
        }
        return false;
    }),
    line1: string().min(15, "Address must be at least 15 characters").required("Address must be at least 15 characters"),
    city: string().required(),
    state: string().required(),
})

export default validationSchema