import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Dropdown from '../../../../components/DropDown';
import HookFormDevTool from '../../../../components/HookFormDevTool';
import Phone from '../../../../components/Phone';
import PrimaryButton from '../../../../components/PrimaryButton';
import StepCardHolder from '../../../../components/StepCardHolder';
import TextField from '../../../../components/TextField';
import { RELATIONSHIPS } from '../../../../constants';
import { getGlobalState, setGlobalState, useGlobalState } from '../../../../store';
import { GTMPush, scrollToNodeWithId } from '../../../../utils';
import validationSchema from './validations';

const EmergencyContactInformation = (_props: any, ref: any) => {
  const { emergencyContactName, emergencyContactPhone, emergencyContactPhoneCode, emergencyContactRelation } = useMemo(() => ({...getGlobalState()}), [])
const { userId } = useGlobalState() as { userId: string };

const {
  register,
  handleSubmit,
  watch,
  setValue,
  getValues,
  control,
  trigger,
  formState: { errors },
} = useForm({
  defaultValues: {
    emergencyContactName: emergencyContactName || undefined, emergencyContactPhoneCode: emergencyContactPhoneCode || undefined, emergencyContactPhone: emergencyContactPhone || undefined, emergencyContactRelation: emergencyContactRelation || undefined,
  },
  resolver: yupResolver(validationSchema),
  mode: 'onBlur',
});
const currentEmergencyContactPhoneCode = watch('emergencyContactPhoneCode')
useEffect(() => {
  setGlobalState({
    emergencyContactPhoneCode: currentEmergencyContactPhoneCode
  });
}, [currentEmergencyContactPhoneCode]);
useImperativeHandle(ref, () => ({
  getData: async () => {
    try {
      const cers = await trigger();
      if (Object.keys(errors).length > 0 || cers === false) {
        scrollToNodeWithId('em-card');
        throw new Error('Fill mandatory fields in emergency contact details')
      }
      const targetData = { ...getValues() };
      return targetData;
    } catch (error) {
      console.log('error in setting');
      throw error;
    }
  },
}));

const onSubmit = async (data: any) => {
  try {
    scrollToNodeWithId('doc-card');
    GTMPush(userId, {
      event: 'nagablr_vp_pushed',
      virtual_path: '/nagablr_vp_emergency_contact_added'
    });
  }
  catch (error) {
    console.log('error in submit', error);
  }
};
return (
  <>
    <StepCardHolder
      heading={'Emergency Contact Information'}
      subHeading={
        'Please provide your emergency contact details.'
      }
      id={'em-card'}
    >
      <div className="responsive-wrapper">
        <TextField
          hookFormRegister={register('emergencyContactName')}
          id={'emergencyContactName'}
          label={'Full Name*'}
          name={'emergencyContactName'}
          placeholderText={'Full Name'}
          errorMessage={errors?.emergencyContactName?.message}
        />
        {/* <TextField
            hookFormRegister={register('emergencyContactEmail')}
            id={'emergencyContactEmail'}
            label={'Email*'}
            name={'emergencyContactEmail'}
            placeholderText={'Email'}
            errorMessage={errors?.emergencyContactEmail?.message}
          /> */}
        <Phone
          label={'Phone*'}
          id={'bi-phone'}
          codeHookFormRegister={register('emergencyContactPhoneCode')}
          phoneHookFormRegister={register('emergencyContactPhone')}
          codeErrorMessage={errors?.emergencyContactPhoneCode?.message}
          phoneErrorMessage={errors?.emergencyContactPhone?.message}
          countryFilter={null}
        // disableCode={true}
        />
        <Dropdown
          hookFormRegister={register('emergencyContactRelation')}
          ariaFor={'emergencyContactRelation'}
          id={'emergencyContactRelation'}
          label={'Relation with contact*'}
          name={'emergencyContactRelation'}
          options={RELATIONSHIPS}
          placeholderOption={'Select Relation'}
          errorMessage={errors?.emergencyContactRelation?.message}
        />
        <PrimaryButton
          text={'NEXT'}
          onClick={handleSubmit(onSubmit)}
          id={`pp-card-submit`}
        />
      </div>
    </StepCardHolder>
    <HookFormDevTool control={control} />
  </>
);
};

export default forwardRef(EmergencyContactInformation);
