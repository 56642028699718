import PaymentForm from 'components/PaymentForm';
import toast from 'react-hot-toast';
import { useGlobalState } from 'store';
import { axiosBridged } from 'utils/network';

const Payment = () => {
    const { existingSubmission, batches } = useGlobalState();

    if (!existingSubmission) {
        return null;
    }
    const { data } = existingSubmission


    const getEReceiptPrice = () => {
        // if (
        //   existingFormSubmission?.channel ===
        //   REGISTRATION_COUPON_CHANNELS.COMPLIMENTARY
        // ) {
        //   if (!targetCoupon?.discountPercentage) {
        //     toast.error('Something went wrong');
        //     return;
        //   }

        //   return targetCoupon?.amount;
        // }

        // if (
        //   existingFormSubmission?.channel ===
        //   REGISTRATION_COUPON_CHANNELS.RE_REGISTRATION
        // ) {

        //   return targetCoupon?.amount;
        // }

        return existingSubmission.price;
    };

    const initPayment = async () => {
        try {
            const response = await axiosBridged.post("/submissions/initPayment", {
                subId: existingSubmission.id,
            }) as IAPIResp<{ price: number }>
            if (response.payload.price === 0) {
                window.location.reload();
            }
        } catch (error: any) {
            if (error?.response?.data?.message)
                toast.error(`Payment Error - ${error?.response?.data?.message}`)
            else
                toast.error("error in submitting form")
            throw (error)
        }
    };

    if (!batches) {
        return null
    }
    const targetBatch = batches.find(a => a.id === existingSubmission.batch)
    if (!targetBatch) {
        return <div>BATCH NOT FOUND</div>
    }
    if (!data) {
        return <div>DATA NOT FOUND</div>
    }

    return (
        <>
            <PaymentForm
                requestId={existingSubmission.id}
                firstName={data.firstName}
                lastName={data.lastName}
                email={data.email}
                amount={getEReceiptPrice()}
                address={data.line1}
                city={data.city}
                state={data.state}
                country={data.countryOfResidence}
                nationality={data.nationality}
                phone={data.phone}
                pinCode={data.pinCode}
                programBay={targetBatch.name}
                programCost={getEReceiptPrice()}
                programParticipantsCount={1}
                eReceiptParams={{}}
                initializePayment={initPayment}
            />

        </>
    )
}

export default Payment